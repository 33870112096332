import type { RootState, AppDispatch } from '@redux/index';
import type { NavSectionProps } from 'src/components/nav-section';

import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveBrand } from '@redux/features/brand/active';
import { fetchSingleBrandById } from '@redux/features/brand/single';

import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import Drawer, { drawerClasses } from '@mui/material/Drawer';

import { useParams, usePathname } from 'src/routes/hooks';

import { varAlpha } from 'src/theme/styles';

import { Scrollbar } from 'src/components/scrollbar';
import { NavSectionVertical } from 'src/components/nav-section';

import { NavUpgrade } from '../components/nav-upgrade';
import { WorkspacesPopover } from '../components/workspaces-popover';

// ----------------------------------------------------------------------

type NavMobileProps = NavSectionProps & {
  isClient: boolean;
  open: boolean;
  onClose: () => void;
  slots?: {
    topArea?: React.ReactNode;
    bottomArea?: React.ReactNode;
  };
};

export const NavMobile = ({
  data,
  isClient,
  open,
  onClose,
  slots,
  sx,
  ...other
}: NavMobileProps) => {
  // ** States
  const [brand, setBrand] = useState<any>();

  // ** Hooks
  const pathname = usePathname();
  const theme = useTheme();
  const { brandId = '' } = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const { authedUser } = useSelector((state: RootState) => state.authedUser);

  useEffect(() => {
    if (open) {
      onClose();
    }

    (async () => {
      if (brandId) {
        const response = await dispatch(fetchSingleBrandById({ id: +brandId }));

        const {
          brandCollection: { edges },
        }: any = response.payload;

        setBrand(edges[0].node);

        // Update active brand
        await dispatch(setActiveBrand(edges[0].node));
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [brandId, dispatch, authedUser, pathname]);

  return (
    <Drawer
      open={open}
      onClose={onClose}
      sx={{
        [`& .${drawerClasses.paper}`]: {
          overflow: 'unset',
          bgcolor: 'var(--layout-nav-bg)',
          width: 'var(--layout-nav-mobile-width)',
          ...sx,
        },
      }}
    >
      {slots?.topArea ?? (
        <>
          {brand && (
            <Box
              sx={{
                mx: 2,
                mt: 2.5,
                mb: 1,
                p: 2,
                borderRadius: 1.25,
                backgroundColor: `var(--nav-item-root-active-bg, ${varAlpha(theme.vars.palette.primary.mainChannel, 0.08)})`,
              }}
            >
              {authedUser.id && <WorkspacesPopover data-slot="workspaces" isClient={isClient} />}
            </Box>
          )}
        </>
      )}

      <Scrollbar fillContent>
        <NavSectionVertical data={data} sx={{ px: 2, flex: '1 1 auto' }} {...other} />
        <NavUpgrade />
      </Scrollbar>

      {slots?.bottomArea}
    </Drawer>
  );
};

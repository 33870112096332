import { gql } from '@apollo/client';

const CORE_RECOGNITION_FIELDS = gql`
  fragment CoreRecognitionFields on recognition {
    nodeId
    id
    slug
    name
    logo
    description
  }
`;

export const GET_RECOGNITIONS = gql`
  ${CORE_RECOGNITION_FIELDS}
  query GetRecognitions($first: Int, $last: Int, $before: Cursor, $after: Cursor) {
    recognitionCollection(first: $first, last: $last, before: $before, after: $after) {
      edges {
        cursor
        node {
          ...CoreRecognitionFields
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
`;

export const FILTERED_RECOGNITIONS = gql`
  ${CORE_RECOGNITION_FIELDS}
  query FilteredRecognitions(
    $first: Int
    $last: Int
    $before: Cursor
    $after: Cursor
    $slug: String
    $name: String
    $ids: [Int]
  ) {
    recognitionCollection(
      first: $first
      last: $last
      before: $before
      after: $after
      filter: { or: { slug: { ilike: $slug }, name: { ilike: $name }, id: { in: $ids } } }
    ) {
      edges {
        cursor
        node {
          ...CoreRecognitionFields
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
`;

export const GET_RECOGNITION_BY_NODE_ID = gql`
  ${CORE_RECOGNITION_FIELDS}
  query GetRecognitionByNodeId($nodeId: ID!) {
    node(nodeId: $nodeId) {
      nodeId
      ... on recognition {
        ...CoreRecognitionFields
      }
    }
  }
`;

export const GET_RECOGNITION_BY_ID = gql`
  ${CORE_RECOGNITION_FIELDS}
  query GetRecognitionById($id: Int!) {
    recognitionCollection(filter: { id: { eq: $id } }) {
      edges {
        cursor
        node {
          ...CoreRecognitionFields
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;

export const CREATE_RECOGNITION = gql`
  ${CORE_RECOGNITION_FIELDS}
  mutation CreateRecognition($slug: String!, $name: String!, $logo: String) {
    insertIntorecognitionCollection(objects: [{ slug: $slug, name: $name, logo: $logo }]) {
      affectedCount
      records {
        ...CoreRecognitionFields
      }
    }
  }
`;

export const UPDATE_RECOGNITION_BY_ID = gql`
  ${CORE_RECOGNITION_FIELDS}
  mutation UpdateRecognitionById(
    $nodeId: ID
    $id: Int
    $slug: String
    $name: String
    $logo: String
  ) {
    updaterecognitionCollection(
      set: { slug: $slug, name: $name, logo: $logo }
      filter: { or: { id: { eq: $id }, nodeId: { eq: $nodeId } } }
    ) {
      affectedCount
      records {
        ...CoreRecognitionFields
      }
    }
  }
`;

export const DELETE_RECOGNITION_BY_ID = gql`
  mutation DeleteRecognitionById($nodeId: ID, $id: Int) {
    deleteFromrecognitionCollection(filter: { or: { id: { eq: $id }, nodeId: { eq: $nodeId } } }) {
      affectedCount
      records {
        id
      }
    }
  }
`;

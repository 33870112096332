import type { Theme, SxProps } from '@mui/material/styles';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import { paths } from 'src/routes/paths';
import { RouterLink } from 'src/routes/components';

// ----------------------------------------------------------------------

interface FooterLink {
  name: string;
  href: string;
}

const LINKS: FooterLink[] = [
  { name: 'Terms of Service', href: paths.termsOfService },
  { name: 'Privacy policy', href: paths.privacyPolicy },
];

export type AuthFooterProps = {
  sx?: SxProps<Theme>;
};

const AuthFooter = ({ sx }: AuthFooterProps) => (
  <Box
    component="footer"
    sx={{
      px: 3.5,
      pb: 3,
      textAlign: 'center',
      position: 'relative',
      zIndex: 'var(--layout-footer-zIndex)',
      ...sx,
    }}
  >
    <Stack flexDirection="row" justifyContent="flex-end" spacing={3.5}>
      {LINKS.map((item, index) => (
        <Link component={RouterLink} href={item.href} key={index} color="inherit">
          <Typography variant="caption">{item.name}</Typography>
        </Link>
      ))}
    </Stack>
  </Box>
);

export default AuthFooter;

// ** Mock Adapter
import type { User } from '@redux/features/auth/types';

import { _mock } from 'src/_mock';
import mock from 'src/@fake-db/mock';

const users: Partial<User>[] = [
  {
    id: '1',
    first_name: 'John',
    last_name: 'Doe',
    phone_number: '+19452658963',
    avatar_url: _mock.image.avatar(24),
    gender: 'male',
    role: 'CTO',
  },
  {
    id: '1',
    first_name: 'Jane',
    last_name: 'Couch',
    phone_number: '+44052658963',
    avatar_url: _mock.image.avatar(24),
    gender: 'female',
    role: 'CFO',
  },
];

mock.onGet('/auth/user').reply(() => [200, users[0]]);

mock.onGet('/auth/users').reply(() => [200, users]);

mock.onPut('/auth/user').reply((payload) => [200, { data: JSON.parse(payload?.data) }]);

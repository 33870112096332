// ----------------------------------------------------------------------

export const paramCase = (str: string): string =>
  str
    .toLowerCase()
    .replace(/\s+/g, '-')
    .replace(/[^a-z0-9-]/g, '');

// ----------------------------------------------------------------------

export const snakeCase = (str: string): string =>
  str
    .toLowerCase()
    .replace(/\s+/g, '_')
    .replace(/[^a-z0-9_]/g, '');

// ----------------------------------------------------------------------

export const sentenceCase = (string: string): string =>
  string.charAt(0).toUpperCase() + string.slice(1);

// ----------------------------------------------------------------------

export const firstCapitalCase = (string: string): string =>
  string
    ?.replace(/[-_]/g, ' ')
    ?.replace(/(^\w{1})|(\s+\w{1})/g, (letter: string) => letter?.toUpperCase());

// ----------------------------------------------------------------------

export const capitalizeWords = (sentence: string): string =>
  sentence
    .split(' ') // Split the sentence into words
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize first letter and lowercase the rest
    .join(' '); // Join the words back into a sentence

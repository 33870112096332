import apolloClient from '@lib/apollo';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  CREATE_PARTNER,
  GET_PARTNER_BY_ID,
  DELETE_PARTNER_BY_ID,
  UPDATE_PARTNER_BY_ID,
  GET_PARTNER_BY_NODE_ID,
} from '@api/partner';

import type { Partner } from '../types';

// ** Partner initial state
const partnerInitialState = {
  nodeId: null,
  id: 0,
  slug: '',
  name: '',
  logo: '',
};

// ** Fetch Single Partner
export const fetchSinglePartner = createAsyncThunk<Partner, { nodeId: string }, {}>(
  'partner/fetchSinglePartner',
  async (nodeId, { rejectWithValue }) => {
    try {
      const { data } = await apolloClient.query({
        query: GET_PARTNER_BY_NODE_ID,
        variables: { ...nodeId },
        fetchPolicy: 'no-cache',
      });

      return data;
    } catch (err) {
      const error: any = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

// ** Fetch Single Partner By ID
export const fetchSinglePartnerById = createAsyncThunk<Partner, { id: number }, {}>(
  'partner/fetchSinglePartnerById',
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await apolloClient.query({
        query: GET_PARTNER_BY_ID,
        variables: { ...id },
        fetchPolicy: 'no-cache',
      });

      return data;
    } catch (err) {
      const error: any = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

// ** Create Partner
export const createPartner = createAsyncThunk<Partner, Partial<Partner>, {}>(
  'partner/createPartner',
  async (partnerData, { rejectWithValue }) => {
    try {
      const { data } = await apolloClient.mutate({
        mutation: CREATE_PARTNER,
        variables: { ...partnerData },
        fetchPolicy: 'no-cache',
      });

      return data;
    } catch (err) {
      const error: any = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

// ** Update Partner
export const updatePartner = createAsyncThunk<Partner, Partial<Partner>, {}>(
  'partner/updatePartner',
  async (partnerData, { rejectWithValue }) => {
    try {
      const { data } = await apolloClient.mutate({
        mutation: UPDATE_PARTNER_BY_ID,
        variables: { ...partnerData },
        fetchPolicy: 'no-cache',
      });

      return data;
    } catch (err) {
      const error: any = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

// ** Delete Partner
export const deletePartner = createAsyncThunk<Partner, Partial<Partner>, {}>(
  'partner/deletePartner',
  async (partnerData, { rejectWithValue }) => {
    try {
      const { data } = await apolloClient.mutate({
        mutation: DELETE_PARTNER_BY_ID,
        variables: { ...partnerData },
        fetchPolicy: 'no-cache',
      });

      return data;
    } catch (err) {
      const error: any = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const singlePartnerSlice = createSlice({
  name: 'partner',
  initialState: {
    partner: <Partial<Partner>>{
      ...partnerInitialState,
    },
    loading: '',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSinglePartner.fulfilled, (state, { payload }) => {
        const { node }: any = payload;

        state.partner = node;
      })
      .addCase(fetchSinglePartnerById.fulfilled, (state, { payload }) => {
        const {
          partnerCollection: { edges },
        }: any = payload;

        state.partner = edges[0].node;
      })
      .addCase(createPartner.fulfilled, (state, { payload }) => {
        const {
          insertIntopartnerCollection: { records },
        }: any = payload;

        state.partner = records[0];
      })
      .addCase(updatePartner.fulfilled, (state, { payload }) => {
        const {
          updatepartnerCollection: { records },
        }: any = payload;

        state.partner = records[0];
      })
      .addCase(deletePartner.fulfilled, (state, { payload }) => {
        const {
          deleteFrompartnerCollection: { records },
        }: any = payload;

        state.partner = records[0];
      });
  },
});

export default singlePartnerSlice.reducer;

import type { UserMetadata } from '@supabase/supabase-js';

import apolloClient from '@lib/apollo';
import { GET_PROFILES, FILTERED_PROFILES } from '@api/profile';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import type { User } from '../auth/types';

// ** User initial state
const usersInitialState = {
  edges: [
    {
      cursor: '',
      node: {
        nodeId: '',
        id: '',
        display_name: '',
        first_name: null,
        last_name: null,
        email: '',
        phone_number: null,
        date_of_birth: null,
        avatar_url: null,
        gender: null,
        account_id: 0,
        account: {
          nodeId: null,
          id: 0,
          salesforce_account_id: null,
          account_name: '',
          abn: null,
          billing_country: null,
          billing_street: null,
          billing_city: null,
          billing_state: null,
          billing_postal_code: null,
          first_name: null,
          last_name: null,
          contact_email: null,
          invoice_email: null,
          phone: null,
          provider_type: null,
          rto_code: null,
          managed_through_self_service: false,
          new_business_flag: false,
          parent_id: null,
          status: null,
          author: null,
          salesforce_sync_status_account: null,
          salesforce_sync_status_brands: null,
          is_migrated_from_salesforce: false,
          should_trigger_notification: false,
          qc_note: null,
          last_published_id: null,
          qc_status: null,
          system_note: null,
          is_fully_synced: null,
        },
      },
    },
  ],
  pageInfo: {
    endCursor: '',
    hasNextPage: false,
    hasPreviousPage: false,
    startCursor: '',
  },
};

interface UsersState {
  edges: {
    cursor: string;
    node: UserMetadata & User;
  }[];
  pageInfo: {
    endCursor: string;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    startCursor: string;
  };
}

// ** Fetch Users
export const fetchUsers = createAsyncThunk<User, any, {}>(
  'users/fetchUsers',
  async (userData, { rejectWithValue }) => {
    try {
      const { data } = await apolloClient.query({
        query: GET_PROFILES,
        variables: { ...userData },
        fetchPolicy: 'no-cache',
      });

      return data;
    } catch (err) {
      const error: any = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

// ** Fetch Filtered Users
export const fetchFilteredUsers = createAsyncThunk<User, any, {}>(
  'users/fetchFilteredUsers',
  async (userData, { rejectWithValue }) => {
    try {
      const { data } = await apolloClient.query({
        query: FILTERED_PROFILES,
        variables: { ...userData },
        fetchPolicy: 'no-cache',
      });

      return data;
    } catch (err) {
      const error: any = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const usersSlice = createSlice({
  name: 'users',
  initialState: {
    users: <UsersState>{
      ...usersInitialState,
    },
    loading: '',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsers.fulfilled, (state, { payload }) => {
        const { profilesCollection }: any = payload;

        state.users = profilesCollection;
      })
      .addCase(fetchFilteredUsers.fulfilled, (state, { payload }) => {
        const { profilesCollection }: any = payload;

        state.users = profilesCollection;
      });
  },
});

export default usersSlice.reducer;

import apolloClient from '@lib/apollo';
import { GET_SUBJECTS, FILTERED_SUBJECTS } from '@api/subject';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import type { Subject } from './types';

// ** Subject initial state
const subjectsInitialState = {
  edges: [
    {
      cursor: '',
      node: {
        nodeId: null,
        id: 0,
        slug: '',
        name: '',
      },
    },
  ],
  pageInfo: {
    endCursor: '',
    hasNextPage: false,
    hasPreviousPage: false,
    startCursor: '',
  },
};

interface SubjectsState {
  edges: {
    cursor: string;
    node: Partial<Subject>;
  }[];
  pageInfo: {
    endCursor: string;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    startCursor: string;
  };
}

// ** Fetch Subjects
export const fetchSubjects = createAsyncThunk<Subject, any, {}>(
  'subjects/fetchSubjects',
  async (subjectData, { rejectWithValue }) => {
    try {
      const { data } = await apolloClient.query({
        query: GET_SUBJECTS,
        variables: { ...subjectData },
        fetchPolicy: 'no-cache',
      });

      return data;
    } catch (err) {
      const error: any = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

// ** Fetch Filtered Subjects
export const fetchFilteredSubjects = createAsyncThunk<Subject, any, {}>(
  'subjects/fetchFilteredSubjects',
  async (subjectData, { rejectWithValue }) => {
    try {
      const { data } = await apolloClient.query({
        query: FILTERED_SUBJECTS,
        variables: { ...subjectData },
        fetchPolicy: 'no-cache',
      });

      return data;
    } catch (err) {
      const error: any = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const subjectsSlice = createSlice({
  name: 'subjects',
  initialState: {
    subjects: <SubjectsState>{
      ...subjectsInitialState,
    },
    loading: '',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSubjects.fulfilled, (state, { payload }) => {
        const { subjectCollection }: any = payload;

        state.subjects = subjectCollection;
      })
      .addCase(fetchFilteredSubjects.fulfilled, (state, { payload }) => {
        const { subjectCollection }: any = payload;

        state.subjects = subjectCollection;
      });
  },
});

export default subjectsSlice.reducer;

import { lazy, Suspense } from 'react';
import { Outlet } from 'react-router-dom';

import { CONFIG } from 'src/config-global';
import { DashboardLayout } from 'src/layouts/dashboard';

import { LoadingScreen } from 'src/components/loading-screen';

import { AuthGuard } from 'src/auth/guard';

import { brandId } from '../paths';

// ----------------------------------------------------------------------

// Overview
const IndexPage = lazy(() => import('src/pages/dashboard'));
const OverviewEcommercePage = lazy(() => import('src/pages/dashboard/ecommerce'));
const OverviewAnalyticsPage = lazy(() => import('src/pages/dashboard/analytics'));
const OverviewBankingPage = lazy(() => import('src/pages/dashboard/banking'));
const OverviewBookingPage = lazy(() => import('src/pages/dashboard/booking'));
const OverviewFilePage = lazy(() => import('src/pages/dashboard/file'));
// Product
const ProductDetailsPage = lazy(() => import('src/pages/dashboard/product/details'));
const ProductListPage = lazy(() => import('src/pages/dashboard/product/list'));
const ProductCreatePage = lazy(() => import('src/pages/dashboard/product/new'));
const ProductEditPage = lazy(() => import('src/pages/dashboard/product/edit'));
// Order
const OrderListPage = lazy(() => import('src/pages/dashboard/order/list'));
const OrderDetailsPage = lazy(() => import('src/pages/dashboard/order/details'));
// Invoice
const InvoiceListPage = lazy(() => import('src/pages/dashboard/invoice/list'));
const InvoiceDetailsPage = lazy(() => import('src/pages/dashboard/invoice/details'));
const InvoiceCreatePage = lazy(() => import('src/pages/dashboard/invoice/new'));
const InvoiceEditPage = lazy(() => import('src/pages/dashboard/invoice/edit'));
// User
const UserProfilePage = lazy(() => import('@pages/dashboard/user/profile'));
const UserCardsPage = lazy(() => import('@pages/dashboard/user/cards'));
const UserListPage = lazy(() => import('@pages/dashboard/user/list'));
const UserAccountPage = lazy(() => import('@pages/dashboard/user/account'));
const UserCreatePage = lazy(() => import('@pages/dashboard/user/new'));
const UserEditPage = lazy(() => import('@pages/dashboard/user/edit'));
// Client
const ClientAccountEditPage = lazy(() => import('@pages/dashboard/client/account'));
// Course
const CourseListPage = lazy(() => import('@pages/dashboard/course/list'));
const CourseEditPage = lazy(() => import('@pages/dashboard/course/edit'));
const LeadCourseCreatePage = lazy(() => import('@pages/dashboard/course/create/lead'));
// Brands
const BrandCreatePage = lazy(() => import('@pages/dashboard/client/brand/create'));
const BrandEditPage = lazy(() => import('@pages/dashboard/client/brand/edit'));
// Campuses
const CampusListPage = lazy(() => import('@pages/dashboard/client/campus/list'));
// Account
const AccountListPage = lazy(() => import('@pages/dashboard/account/list'));
const AccountCreateSystemAccountPage = lazy(
  () => import('@pages/dashboard/account/create/system-account')
);
const AccountCreateClientAccountPage = lazy(
  () => import('@pages/dashboard/account/create/client-account')
);
const AccountEditPage = lazy(() => import('@pages/dashboard/account/edit'));
// Team
const TeamListPage = lazy(() => import('@pages/dashboard/client/team/list'));
// Subjects
const SubjectListPage = lazy(() => import('@pages/dashboard/self-service/subject/list'));
// Recognitions
const RecognitionListPage = lazy(() => import('@pages/dashboard/self-service/recognition/list'));
// Partners
const PartnerListPage = lazy(() => import('@pages/dashboard/self-service/partner/list'));
// Blog
const BlogPostsPage = lazy(() => import('src/pages/dashboard/post/list'));
const BlogPostPage = lazy(() => import('src/pages/dashboard/post/details'));
const BlogNewPostPage = lazy(() => import('src/pages/dashboard/post/new'));
const BlogEditPostPage = lazy(() => import('src/pages/dashboard/post/edit'));
// Job
const JobDetailsPage = lazy(() => import('src/pages/dashboard/job/details'));
const JobListPage = lazy(() => import('src/pages/dashboard/job/list'));
const JobCreatePage = lazy(() => import('src/pages/dashboard/job/new'));
const JobEditPage = lazy(() => import('src/pages/dashboard/job/edit'));
// Tour
const TourDetailsPage = lazy(() => import('src/pages/dashboard/tour/details'));
const TourListPage = lazy(() => import('src/pages/dashboard/tour/list'));
const TourCreatePage = lazy(() => import('src/pages/dashboard/tour/new'));
const TourEditPage = lazy(() => import('src/pages/dashboard/tour/edit'));
// File manager
const FileManagerPage = lazy(() => import('src/pages/dashboard/file-manager'));
// App
const ChatPage = lazy(() => import('src/pages/dashboard/chat'));
const MailPage = lazy(() => import('src/pages/dashboard/mail'));
const CalendarPage = lazy(() => import('src/pages/dashboard/calendar'));
const KanbanPage = lazy(() => import('src/pages/dashboard/kanban'));
// Test render page by role
const PermissionDeniedPage = lazy(() => import('src/pages/dashboard/permission'));
// Blank page
const ParamsPage = lazy(() => import('src/pages/dashboard/params'));
const BlankPage = lazy(() => import('src/pages/dashboard/blank'));

// ----------------------------------------------------------------------

const layoutContent = (
  <DashboardLayout>
    <Suspense fallback={<LoadingScreen />}>
      <Outlet />
    </Suspense>
  </DashboardLayout>
);

export const dashboardRoutes = [
  {
    path: brandId ? 'dashboard/:brandId' : 'dashboard',
    element: CONFIG.auth.skip ? <>{layoutContent}</> : <AuthGuard>{layoutContent}</AuthGuard>,
    children: [
      { element: <IndexPage />, index: true },
      { path: 'ecommerce', element: <OverviewEcommercePage /> },
      { path: 'analytics', element: <OverviewAnalyticsPage /> },
      { path: 'banking', element: <OverviewBankingPage /> },
      { path: 'booking', element: <OverviewBookingPage /> },
      { path: 'file', element: <OverviewFilePage /> },
      {
        path: 'user',
        children: [
          { element: <UserProfilePage />, index: true },
          { path: 'profile', element: <UserProfilePage /> },
          { path: 'cards', element: <UserCardsPage /> },
          { path: 'list', element: <UserListPage /> },
          { path: 'new', element: <UserCreatePage /> },
          { path: ':id/edit', element: <UserEditPage /> },
          { path: 'account', element: <UserAccountPage /> },
        ],
      },
      {
        path: 'client',
        children: [
          { element: <ClientAccountEditPage />, index: true },
          { path: 'account', element: <ClientAccountEditPage /> },
          { path: 'brand/create', element: <BrandCreatePage /> },
          { path: 'brand/edit', element: <BrandEditPage /> },
          { path: 'campus/list', element: <CampusListPage /> },
          { path: 'team/list', element: <TeamListPage /> },
        ],
      },
      {
        path: 'course',
        children: [
          { element: <CourseListPage />, index: true },
          { path: 'list', element: <CourseListPage /> },
          { path: 'create/lead', element: <LeadCourseCreatePage /> },
          { path: ':courseId/edit', element: <CourseEditPage /> },
        ],
      },
      {
        path: 'account',
        children: [
          { element: <AccountListPage />, index: true },
          { path: 'list', element: <AccountListPage /> },
          { path: 'create/system-account', element: <AccountCreateSystemAccountPage /> },
          { path: 'create/client-account', element: <AccountCreateClientAccountPage /> },
          { path: ':id/edit', element: <AccountEditPage /> },
        ],
      },
      {
        path: 'self-service',
        children: [
          { element: <SubjectListPage />, index: true },
          { path: 'subject/list', element: <SubjectListPage /> },
          { path: 'recognition/list', element: <RecognitionListPage /> },
          { path: 'partner/list', element: <PartnerListPage /> },
        ],
      },
      {
        path: 'product',
        children: [
          { element: <ProductListPage />, index: true },
          { path: 'list', element: <ProductListPage /> },
          { path: ':id', element: <ProductDetailsPage /> },
          { path: 'new', element: <ProductCreatePage /> },
          { path: ':id/edit', element: <ProductEditPage /> },
        ],
      },
      {
        path: 'order',
        children: [
          { element: <OrderListPage />, index: true },
          { path: 'list', element: <OrderListPage /> },
          { path: ':id', element: <OrderDetailsPage /> },
        ],
      },
      {
        path: 'invoice',
        children: [
          { element: <InvoiceListPage />, index: true },
          { path: 'list', element: <InvoiceListPage /> },
          { path: ':id', element: <InvoiceDetailsPage /> },
          { path: ':id/edit', element: <InvoiceEditPage /> },
          { path: 'new', element: <InvoiceCreatePage /> },
        ],
      },
      {
        path: 'post',
        children: [
          { element: <BlogPostsPage />, index: true },
          { path: 'list', element: <BlogPostsPage /> },
          { path: ':title', element: <BlogPostPage /> },
          { path: ':title/edit', element: <BlogEditPostPage /> },
          { path: 'new', element: <BlogNewPostPage /> },
        ],
      },
      {
        path: 'job',
        children: [
          { element: <JobListPage />, index: true },
          { path: 'list', element: <JobListPage /> },
          { path: ':id', element: <JobDetailsPage /> },
          { path: 'new', element: <JobCreatePage /> },
          { path: ':id/edit', element: <JobEditPage /> },
        ],
      },
      {
        path: 'tour',
        children: [
          { element: <TourListPage />, index: true },
          { path: 'list', element: <TourListPage /> },
          { path: ':id', element: <TourDetailsPage /> },
          { path: 'new', element: <TourCreatePage /> },
          { path: ':id/edit', element: <TourEditPage /> },
        ],
      },
      { path: 'file-manager', element: <FileManagerPage /> },
      { path: 'mail', element: <MailPage /> },
      { path: 'chat', element: <ChatPage /> },
      { path: 'calendar', element: <CalendarPage /> },
      { path: 'kanban', element: <KanbanPage /> },
      { path: 'permission', element: <PermissionDeniedPage /> },
      { path: 'params', element: <ParamsPage /> },
      { path: 'blank', element: <BlankPage /> },
    ],
  },
];

import apolloClient from '@lib/apollo';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  GET_QUALIFICATIONS,
  LOOKUP_QUALIFICATIONS,
  FILTERED_QUALIFICATIONS,
} from '@api/qualification';

import type { Qualification } from './types';

// ** Qualification initial state
const qualificationsInitialState = {
  edges: [
    {
      cursor: '',
      node: {
        nodeId: null,
        course_code: '',
        course_name: '',
      },
    },
  ],
  pageInfo: {
    endCursor: '',
    hasNextPage: false,
    hasPreviousPage: false,
    startCursor: '',
  },
};

interface QualificationsState {
  edges: {
    cursor: string;
    node: Partial<Qualification>;
  }[];
  pageInfo: {
    endCursor: string;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    startCursor: string;
  };
}

// ** Fetch Qualifications
export const fetchQualifications = createAsyncThunk<Qualification, any, {}>(
  'qualifications/fetchQualifications',
  async (qualificationData, { rejectWithValue }) => {
    try {
      const { data } = await apolloClient.query({
        query: GET_QUALIFICATIONS,
        variables: { ...qualificationData },
        fetchPolicy: 'no-cache',
      });

      return data;
    } catch (err) {
      const error: any = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

// ** Fetch Filtered Qualifications
export const fetchFilteredQualifications = createAsyncThunk<Qualification, any, {}>(
  'qualifications/fetchFilteredQualifications',
  async (qualificationData, { rejectWithValue }) => {
    try {
      const { data } = await apolloClient.query({
        query: FILTERED_QUALIFICATIONS,
        variables: { ...qualificationData },
        fetchPolicy: 'no-cache',
      });

      return data;
    } catch (err) {
      const error: any = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

// ** Lookup Qualifications
export const lookupQualifications = createAsyncThunk<Qualification, any, {}>(
  'qualifications/lookupQualifications',
  async (qualificationData, { rejectWithValue }) => {
    try {
      const { data } = await apolloClient.query({
        query: LOOKUP_QUALIFICATIONS,
        variables: { ...qualificationData },
        fetchPolicy: 'no-cache',
      });

      return data;
    } catch (err) {
      const error: any = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const qualificationsSlice = createSlice({
  name: 'qualifications',
  initialState: {
    qualifications: <QualificationsState>{
      ...qualificationsInitialState,
    },
    loading: '',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchQualifications.fulfilled, (state, { payload }) => {
        const { qualificationCollection }: any = payload;

        state.qualifications = qualificationCollection;
      })
      .addCase(fetchFilteredQualifications.fulfilled, (state, { payload }) => {
        const { qualificationCollection }: any = payload;

        state.qualifications = qualificationCollection;
      })
      .addCase(lookupQualifications.fulfilled, (state, { payload }) => {
        const { qualificationCollection }: any = payload;

        state.qualifications = qualificationCollection;
      });
  },
});

export default qualificationsSlice.reducer;

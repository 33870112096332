import apolloClient from '@lib/apollo';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  CREATE_ACCOUNT,
  GET_ACCOUNT_BY_ID,
  DELETE_ACCOUNT_BY_ID,
  UPDATE_ACCOUNT_BY_ID,
  GET_ACCOUNT_BY_NODE_ID,
} from '@api/account';

import type { Account } from '../types';

// ** Account initial state
const accountInitialState = {
  nodeId: null,
  id: 0,
  salesforce_account_id: null,
  account_name: '',
  abn: null,
  billing_country: null,
  billing_street: null,
  billing_city: null,
  billing_state: null,
  billing_postal_code: null,
  first_name: null,
  last_name: null,
  contact_email: null,
  invoice_email: null,
  phone: null,
  provider_type: null,
  rto_code: null,
  managed_through_self_service: false,
  new_business_flag: false,
  parent_id: null,
  status: null,
  author: null,
  salesforce_sync_status_account: null,
  salesforce_sync_status_brands: null,
  is_migrated_from_salesforce: false,
  should_trigger_notification: false,
  qc_note: null,
  last_published_id: null,
  qc_status: null,
  system_note: null,
  is_fully_synced: null,
};

// ** Fetch Single Account
export const fetchSingleAccount = createAsyncThunk<Account, { nodeId: string }, {}>(
  'account/fetchSingleAccount',
  async (nodeId, { rejectWithValue }) => {
    try {
      const { data } = await apolloClient.query({
        query: GET_ACCOUNT_BY_NODE_ID,
        variables: { ...nodeId },
        fetchPolicy: 'no-cache',
      });

      return data;
    } catch (err) {
      const error: any = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

// ** Fetch Single Account By ID
export const fetchSingleAccountById = createAsyncThunk<Account, { id: number }, {}>(
  'account/fetchSingleAccountById',
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await apolloClient.query({
        query: GET_ACCOUNT_BY_ID,
        variables: { ...id },
        fetchPolicy: 'no-cache',
      });

      return data;
    } catch (err) {
      const error: any = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

// ** Create Account
export const createAccount = createAsyncThunk<Account, Partial<Account>, {}>(
  'account/createAccount',
  async (accountData, { rejectWithValue }) => {
    try {
      const { data } = await apolloClient.mutate({
        mutation: CREATE_ACCOUNT,
        variables: { ...accountData },
        fetchPolicy: 'no-cache',
      });

      return data;
    } catch (err) {
      const error: any = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

// ** Update Account
export const updateAccount = createAsyncThunk<Account, Partial<Account>, {}>(
  'account/updateAccount',
  async (accountData, { rejectWithValue }) => {
    try {
      const { data } = await apolloClient.mutate({
        mutation: UPDATE_ACCOUNT_BY_ID,
        variables: { ...accountData },
        fetchPolicy: 'no-cache',
      });

      return data;
    } catch (err) {
      const error: any = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

// ** Delete Account
export const deleteAccount = createAsyncThunk<Account, Partial<Account>, {}>(
  'account/deleteAccount',
  async (accountData, { rejectWithValue }) => {
    try {
      const { data } = await apolloClient.mutate({
        mutation: DELETE_ACCOUNT_BY_ID,
        variables: { ...accountData },
        fetchPolicy: 'no-cache',
      });

      return data;
    } catch (err) {
      const error: any = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const singleAccountSlice = createSlice({
  name: 'account',
  initialState: {
    account: <Partial<Account>>{
      ...accountInitialState,
    },
    loading: '',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSingleAccount.fulfilled, (state, { payload }) => {
        const { node }: any = payload;

        state.account = node;
      })
      .addCase(fetchSingleAccountById.fulfilled, (state, { payload }) => {
        const {
          accountCollection: { edges },
        }: any = payload;

        state.account = edges[0].node;
      })
      .addCase(createAccount.fulfilled, (state, { payload }) => {
        const {
          insertIntoaccountCollection: { records },
        }: any = payload;

        state.account = records[0];
      })
      .addCase(updateAccount.fulfilled, (state, { payload }) => {
        const {
          updateaccountCollection: { records },
        }: any = payload;

        state.account = records[0];
      })
      .addCase(deleteAccount.fulfilled, (state, { payload }) => {
        const {
          deleteFromaccountCollection: { records },
        }: any = payload;

        state.account = records[0];
      });
  },
});

export default singleAccountSlice.reducer;

import { setContext } from '@apollo/client/link/context';
import { ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';

import { CONFIG } from 'src/config-global';

import { supabase } from './supabase';

const httpLink = createHttpLink({
  uri: `${CONFIG.supabase.url}/graphql/v1`,
});

const authLink = setContext(async (_, { headers }) => {
  const token = (await supabase.auth.getSession()).data.session?.access_token;

  return {
    headers: {
      ...headers,
      apikey: CONFIG.supabase.key,
      Authorization: `Bearer ${token || CONFIG.supabase.key}`,
    },
  };
});

const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});

export default apolloClient;

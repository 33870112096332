import { gql } from '@apollo/client';

const CORE_ACCOUNT_FIELDS = gql`
  fragment CoreAccountFields on account {
    nodeId
    id
    salesforce_account_id
    account_name
    abn
    billing_country
    billing_street
    billing_city
    billing_state
    billing_postal_code
    first_name
    last_name
    contact_email
    invoice_email
    phone
    provider_type
    rto_code
    managed_through_self_service
    new_business_flag
    parent_id
    status
    author
    salesforce_sync_status_account
    salesforce_sync_status_brands
    is_migrated_from_salesforce
    should_trigger_notification
    qc_note
    last_published_id
    qc_status
    system_note
    is_fully_synced
  }
`;

export const GET_ACCOUNTS = gql`
  ${CORE_ACCOUNT_FIELDS}
  query GetAccounts($first: Int, $last: Int, $before: Cursor, $after: Cursor) {
    accountCollection(first: $first, last: $last, before: $before, after: $after) {
      edges {
        cursor
        node {
          ...CoreAccountFields
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
`;

export const FILTERED_ACCOUNTS = gql`
  ${CORE_ACCOUNT_FIELDS}
  query FilteredAccounts(
    $first: Int
    $last: Int
    $before: Cursor
    $after: Cursor
    $orderBy: any
    $first_name: String
    $last_name: String
    $account_name: String
    $contact_email: String
    $phone: String
    $parent_id: Int
  ) {
    accountCollection(
      first: $first
      last: $last
      before: $before
      after: $after
      orderBy: $orderBy
      filter: {
        or: {
          first_name: { ilike: $first_name }
          last_name: { ilike: $last_name }
          account_name: { ilike: $account_name }
          contact_email: { eq: $contact_email }
          phone: { ilike: $phone }
          parent_id: { eq: $parent_id }
        }
      }
    ) {
      edges {
        cursor
        node {
          ...CoreAccountFields
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
`;

export const GET_ACCOUNT_BY_NODE_ID = gql`
  ${CORE_ACCOUNT_FIELDS}
  query GetAccountByNodeId($nodeId: ID!) {
    node(nodeId: $nodeId) {
      nodeId
      ... on account {
        ...CoreAccountFields
      }
    }
  }
`;

export const GET_ACCOUNT_BY_ID = gql`
  ${CORE_ACCOUNT_FIELDS}
  query GetAccountById($id: Int!) {
    accountCollection(filter: { id: { eq: $id } }) {
      edges {
        cursor
        node {
          ...CoreAccountFields
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;

export const CREATE_ACCOUNT = gql`
  ${CORE_ACCOUNT_FIELDS}
  mutation CreateAccount(
    $salesforce_account_id: String
    $account_name: String
    $abn: String
    $billing_country: String
    $billing_street: String
    $billing_city: String
    $billing_state: String
    $billing_postal_code: String
    $first_name: String
    $last_name: String
    $contact_email: String
    $invoice_email: String
    $phone: String
    $provider_type: String
    $rto_code: String
    $managed_through_self_service: Boolean
    $new_business_flag: Boolean
    $parent_id: Int
    $status: String
    $author: UUID
    $salesforce_sync_status_account: String
    $salesforce_sync_status_brands: String
    $is_migrated_from_salesforce: Boolean
    $should_trigger_notification: Boolean
    $qc_note: String
    $last_published_id: Int
    $qc_status: String
  ) {
    insertIntoaccountCollection(
      objects: [
        {
          salesforce_account_id: $salesforce_account_id
          account_name: $account_name
          abn: $abn
          billing_country: $billing_country
          billing_street: $billing_street
          billing_city: $billing_city
          billing_state: $billing_state
          billing_postal_code: $billing_postal_code
          first_name: $first_name
          last_name: $last_name
          contact_email: $contact_email
          invoice_email: $invoice_email
          phone: $phone
          provider_type: $provider_type
          rto_code: $rto_code
          managed_through_self_service: $managed_through_self_service
          new_business_flag: $new_business_flag
          parent_id: $parent_id
          status: $status
          author: $author
          salesforce_sync_status_account: $salesforce_sync_status_account
          salesforce_sync_status_brands: $salesforce_sync_status_brands
          is_migrated_from_salesforce: $is_migrated_from_salesforce
          should_trigger_notification: $should_trigger_notification
          qc_note: $qc_note
          last_published_id: $last_published_id
          qc_status: $qc_status
        }
      ]
    ) {
      affectedCount
      records {
        ...CoreAccountFields
      }
    }
  }
`;

export const UPDATE_ACCOUNT_BY_ID = gql`
  ${CORE_ACCOUNT_FIELDS}
  mutation UpdateAccountById(
    $nodeId: ID
    $id: Int
    $salesforce_account_id: String
    $account_name: String
    $abn: String
    $billing_country: String
    $billing_street: String
    $billing_city: String
    $billing_state: String
    $billing_postal_code: String
    $first_name: String
    $last_name: String
    $contact_email: String
    $invoice_email: String
    $phone: String
    $provider_type: String
    $rto_code: String
    $managed_through_self_service: Boolean
    $new_business_flag: Boolean
    $parent_id: Int
    $status: String
    $author: UUID
    $salesforce_sync_status_account: String
    $salesforce_sync_status_brands: String
    $is_migrated_from_salesforce: Boolean
    $should_trigger_notification: Boolean
    $qc_note: String
    $last_published_id: Int
    $qc_status: String
  ) {
    updateaccountCollection(
      set: {
        salesforce_account_id: $salesforce_account_id
        account_name: $account_name
        abn: $abn
        billing_country: $billing_country
        billing_street: $billing_street
        billing_city: $billing_city
        billing_state: $billing_state
        billing_postal_code: $billing_postal_code
        first_name: $first_name
        last_name: $last_name
        contact_email: $contact_email
        invoice_email: $invoice_email
        phone: $phone
        provider_type: $provider_type
        rto_code: $rto_code
        managed_through_self_service: $managed_through_self_service
        new_business_flag: $new_business_flag
        parent_id: $parent_id
        status: $status
        author: $author
        salesforce_sync_status_account: $salesforce_sync_status_account
        salesforce_sync_status_brands: $salesforce_sync_status_brands
        is_migrated_from_salesforce: $is_migrated_from_salesforce
        should_trigger_notification: $should_trigger_notification
        qc_note: $qc_note
        last_published_id: $last_published_id
        qc_status: $qc_status
      }
      filter: { or: { id: { eq: $id }, nodeId: { eq: $nodeId } } }
    ) {
      affectedCount
      records {
        ...CoreAccountFields
      }
    }
  }
`;

export const DELETE_ACCOUNT_BY_ID = gql`
  mutation DeleteAccountById($nodeId: ID, $id: Int) {
    deleteFromaccountCollection(filter: { or: { id: { eq: $id }, nodeId: { eq: $nodeId } } }) {
      affectedCount
      records {
        id
      }
    }
  }
`;

import apolloClient from '@lib/apollo';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  CREATE_RECOGNITION,
  GET_RECOGNITION_BY_ID,
  DELETE_RECOGNITION_BY_ID,
  UPDATE_RECOGNITION_BY_ID,
  GET_RECOGNITION_BY_NODE_ID,
} from '@api/recognition';

import type { Recognition } from '../types';

// ** Recognition initial state
const recognitionInitialState = {
  nodeId: null,
  id: 0,
  slug: '',
  name: '',
  logo: '',
  description: '',
};

// ** Fetch Single Recognition
export const fetchSingleRecognition = createAsyncThunk<Recognition, { nodeId: string }, {}>(
  'recognition/fetchSingleRecognition',
  async (nodeId, { rejectWithValue }) => {
    try {
      const { data } = await apolloClient.query({
        query: GET_RECOGNITION_BY_NODE_ID,
        variables: { ...nodeId },
        fetchPolicy: 'no-cache',
      });

      return data;
    } catch (err) {
      const error: any = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

// ** Fetch Single Recognition By ID
export const fetchSingleRecognitionById = createAsyncThunk<Recognition, { id: number }, {}>(
  'recognition/fetchSingleRecognitionById',
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await apolloClient.query({
        query: GET_RECOGNITION_BY_ID,
        variables: { ...id },
        fetchPolicy: 'no-cache',
      });

      return data;
    } catch (err) {
      const error: any = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

// ** Create Recognition
export const createRecognition = createAsyncThunk<Recognition, Partial<Recognition>, {}>(
  'recognition/createRecognition',
  async (recognitionData, { rejectWithValue }) => {
    try {
      const { data } = await apolloClient.mutate({
        mutation: CREATE_RECOGNITION,
        variables: { ...recognitionData },
        fetchPolicy: 'no-cache',
      });

      return data;
    } catch (err) {
      const error: any = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

// ** Update Recognition
export const updateRecognition = createAsyncThunk<Recognition, Partial<Recognition>, {}>(
  'recognition/updateRecognition',
  async (recognitionData, { rejectWithValue }) => {
    try {
      const { data } = await apolloClient.mutate({
        mutation: UPDATE_RECOGNITION_BY_ID,
        variables: { ...recognitionData },
        fetchPolicy: 'no-cache',
      });

      return data;
    } catch (err) {
      const error: any = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

// ** Delete Recognition
export const deleteRecognition = createAsyncThunk<Recognition, Partial<Recognition>, {}>(
  'recognition/deleteRecognition',
  async (recognitionData, { rejectWithValue }) => {
    try {
      const { data } = await apolloClient.mutate({
        mutation: DELETE_RECOGNITION_BY_ID,
        variables: { ...recognitionData },
        fetchPolicy: 'no-cache',
      });

      return data;
    } catch (err) {
      const error: any = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const singleRecognitionSlice = createSlice({
  name: 'recognition',
  initialState: {
    recognition: <Partial<Recognition>>{
      ...recognitionInitialState,
    },
    loading: '',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSingleRecognition.fulfilled, (state, { payload }) => {
        const { node }: any = payload;

        state.recognition = node;
      })
      .addCase(fetchSingleRecognitionById.fulfilled, (state, { payload }) => {
        const {
          recognitionCollection: { edges },
        }: any = payload;

        state.recognition = edges[0].node;
      })
      .addCase(createRecognition.fulfilled, (state, { payload }) => {
        const {
          insertIntorecognitionCollection: { records },
        }: any = payload;

        state.recognition = records[0];
      })
      .addCase(updateRecognition.fulfilled, (state, { payload }) => {
        const {
          updaterecognitionCollection: { records },
        }: any = payload;

        state.recognition = records[0];
      })
      .addCase(deleteRecognition.fulfilled, (state, { payload }) => {
        const {
          deleteFromrecognitionCollection: { records },
        }: any = payload;

        state.recognition = records[0];
      });
  },
});

export default singleRecognitionSlice.reducer;

import apolloClient from '@lib/apollo';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  GET_NOTIFICATION_BY_ID,
  UPDATE_NOTIFICATION_BY_ID,
  GET_NOTIFICATION_BY_NODE_ID,
} from '@api/notification';

import type { Notification } from '../types';

// ** Notification initial state
const notificationInitialState = {
  nodeId: null,
  id: 0,
  title: '',
  content: '',
  notification_type: undefined,
  visibility: undefined,
  account_id: 0,
  brand_id: null,
  course_id: null,
  created_at: null,
  author: null,
  read_at: null,
  read_by: null,
  required_permission_id: null,
  account: {
    account_name: undefined,
  },
  brand: {
    provider_name: null,
    logo: null,
  },
  course: {
    course_name: null,
  },
};

// ** Fetch Single Notification
export const fetchSingleNotification = createAsyncThunk<Notification, { nodeId: string }, {}>(
  'notification/fetchSingleNotification',
  async (nodeId, { rejectWithValue }) => {
    try {
      const { data } = await apolloClient.query({
        query: GET_NOTIFICATION_BY_NODE_ID,
        variables: { ...nodeId },
        fetchPolicy: 'no-cache',
      });

      return data;
    } catch (err) {
      const error: any = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

// ** Fetch Single Notification By ID
export const fetchSingleNotificationById = createAsyncThunk<Notification, { id: number }, {}>(
  'notification/fetchSingleNotificationById',
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await apolloClient.query({
        query: GET_NOTIFICATION_BY_ID,
        variables: { ...id },
        fetchPolicy: 'no-cache',
      });

      return data;
    } catch (err) {
      const error: any = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

// ** Update Notification
export const updateNotification = createAsyncThunk<Notification, Partial<Notification>, {}>(
  'notification/updateNotification',
  async (notificationData, { rejectWithValue }) => {
    try {
      const { data } = await apolloClient.mutate({
        mutation: UPDATE_NOTIFICATION_BY_ID,
        variables: { ...notificationData },
        fetchPolicy: 'no-cache',
      });

      return data;
    } catch (err) {
      const error: any = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const singleNotificationSlice = createSlice({
  name: 'notification',
  initialState: {
    notification: <Partial<Notification>>{
      ...notificationInitialState,
    },
    loading: '',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSingleNotification.fulfilled, (state, { payload }) => {
        const { node }: any = payload;

        state.notification = node;
      })
      .addCase(fetchSingleNotificationById.fulfilled, (state, { payload }) => {
        const {
          notificationCollection: { edges },
        }: any = payload;

        state.notification = edges[0].node;
      })
      .addCase(updateNotification.fulfilled, (state, { payload }) => {
        const {
          updatenotificationCollection: { records },
        }: any = payload;

        state.notification = records[0];
      });
  },
});

export default singleNotificationSlice.reducer;

import apolloClient from '@lib/apollo';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  CREATE_COURSE_CAMPUS,
  GET_COURSE_CAMPUS_BY_ID,
  DELETE_COURSE_CAMPUS_BY_ID,
  UPDATE_COURSE_CAMPUS_BY_ID,
  GET_COURSE_CAMPUS_BY_NODE_ID,
} from '@api/course-campus';

import type { CourseCampus } from '../types';

// ** Course-Campus initial state
const courseCampusInitialState = {
  nodeId: null,
  id: 0,
  course_id: 0,
  location_data_id: 0,
};

// ** Fetch Single CourseCampus
export const fetchSingleCourseCampus = createAsyncThunk<CourseCampus, { nodeId: string }, {}>(
  'courseCampus/fetchSingleCourseCampus',
  async (nodeId, { rejectWithValue }) => {
    try {
      const { data } = await apolloClient.query({
        query: GET_COURSE_CAMPUS_BY_NODE_ID,
        variables: { ...nodeId },
        fetchPolicy: 'no-cache',
      });

      return data;
    } catch (err) {
      const error: any = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

// ** Fetch Single CourseCampus By ID
export const fetchSingleCourseCampusById = createAsyncThunk<CourseCampus, { id: number }, {}>(
  'courseCampus/fetchSingleCourseCampusById',
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await apolloClient.query({
        query: GET_COURSE_CAMPUS_BY_ID,
        variables: { ...id },
        fetchPolicy: 'no-cache',
      });

      return data;
    } catch (err) {
      const error: any = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

// ** Create Course-Campus
export const createCourseCampus = createAsyncThunk<CourseCampus, any, {}>(
  'courseCampus/createCourseCampus',
  async (courseCampusData, { rejectWithValue }) => {
    try {
      const { data } = await apolloClient.mutate({
        mutation: CREATE_COURSE_CAMPUS,
        variables: { records: courseCampusData },
        fetchPolicy: 'no-cache',
      });

      return data;
    } catch (err) {
      const error: any = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

// ** Update CourseCampus
export const updateCourseCampus = createAsyncThunk<CourseCampus, Partial<CourseCampus>, {}>(
  'courseCampus/updateCourseCampus',
  async (courseCampusData, { rejectWithValue }) => {
    try {
      const { data } = await apolloClient.mutate({
        mutation: UPDATE_COURSE_CAMPUS_BY_ID,
        variables: { ...courseCampusData },
        fetchPolicy: 'no-cache',
      });

      return data;
    } catch (err) {
      const error: any = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

// ** Delete CourseCampus
export const deleteCourseCampus = createAsyncThunk<CourseCampus, any, {}>(
  'courseCampus/deleteCourseCampus',
  async (courseCampusData, { rejectWithValue }) => {
    try {
      const { data } = await apolloClient.mutate({
        mutation: DELETE_COURSE_CAMPUS_BY_ID,
        variables: { ids: courseCampusData },
        fetchPolicy: 'no-cache',
      });

      return data;
    } catch (err) {
      const error: any = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const singleCourseCampusSlice = createSlice({
  name: 'courseCampus',
  initialState: {
    courseCampus: <Partial<CourseCampus>>{
      ...courseCampusInitialState,
    },
    loading: '',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSingleCourseCampus.fulfilled, (state, { payload }) => {
        const { node }: any = payload;

        state.courseCampus = node;
      })
      .addCase(fetchSingleCourseCampusById.fulfilled, (state, { payload }) => {
        const {
          course_campus_locationCollection: { edges },
        }: any = payload;

        state.courseCampus = edges[0].node;
      })
      .addCase(createCourseCampus.fulfilled, (state, { payload }) => {
        const {
          insertIntocourse_campus_locationCollection: { records },
        }: any = payload;

        state.courseCampus = records[0];
      })
      .addCase(updateCourseCampus.fulfilled, (state, { payload }) => {
        const {
          updatecourse_campus_locationCollection: { records },
        }: any = payload;

        state.courseCampus = records[0];
      })
      .addCase(deleteCourseCampus.fulfilled, (state, { payload }) => {
        const {
          deleteFromcourse_campus_locationCollection: { records },
        }: any = payload;

        state.courseCampus = records[0];
      });
  },
});

export default singleCourseCampusSlice.reducer;

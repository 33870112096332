import { gql } from '@apollo/client';

const CORE_COURSE_FIELDS = gql`
  fragment CoreCourseFields on course {
    nodeId
    id
    additional_information
    assessment
    author
    brand_id
    course_code
    course_code2
    course_features
    course_guide_url
    course_name
    course_price
    course_type
    curriculum_introduction
    delivery
    delivery_long_text
    duration_and_study_load
    duration_length
    duration_unit
    entry_requirements
    fee_help
    further_study_and_education_pathways
    geo_targeting
    guid
    is_dual_qualification
    is_migrated_from_salesforce
    job_outcomes
    last_published_id
    level
    locations
    materials
    offer_type
    overview
    parent_id
    pause_course_on_all_sites
    payment_options
    professional_recognition
    published_shortcourses
    published_tafecourses
    published_training
    qc_note
    qc_status
    qualification
    recognition_of_prior_learning
    should_trigger_notification
    status
    testimonies
    updated_at
    video
    what_youll_learn
    who_is_it_for
    wordpress_sync_status
    work_placement
    system_note
    start_date
    frequency
    experience_level
    partner_id
    internal_note
    top_panel
    course_module
    api_id_value
    grouping_value
    course_code_value
    lead_cost
  }
`;

export const GET_COURSES = gql`
  ${CORE_COURSE_FIELDS}
  query GetCourses($first: Int, $last: Int, $before: Cursor, $after: Cursor, $orderBy: any) {
    courseCollection(
      first: $first
      last: $last
      before: $before
      after: $after
      orderBy: $orderBy
    ) {
      edges {
        cursor
        node {
          ...CoreCourseFields
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
`;

export const FILTERED_COURSES = gql`
  ${CORE_COURSE_FIELDS}
  query FilteredCourses(
    $first: Int
    $last: Int
    $before: Cursor
    $after: Cursor
    $orderBy: any
    $brand_id: Int
    $course_code: String
    $course_name: String
    $course_type: String
    $offer_type: String
    $parent_id: Int
    $status: [String]
  ) {
    courseCollection(
      first: $first
      last: $last
      before: $before
      after: $after
      orderBy: $orderBy
      filter: {
        or: {
          brand_id: { eq: $brand_id }
          course_code: { eq: $course_code }
          course_name: { ilike: $course_name }
          course_type: { eq: $course_type }
          offer_type: { eq: $offer_type }
          parent_id: { eq: $parent_id }
          status: { in: $status }
        }
      }
    ) {
      edges {
        cursor
        node {
          ...CoreCourseFields
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
`;

export const GET_COURSE_BY_NODE_ID = gql`
  ${CORE_COURSE_FIELDS}
  query GetCourseByNodeId($nodeId: ID!) {
    node(nodeId: $nodeId) {
      nodeId
      ... on course {
        ...CoreCourseFields
      }
    }
  }
`;

export const GET_COURSE_BY_ID = gql`
  ${CORE_COURSE_FIELDS}
  query GetCourseById($id: Int!) {
    courseCollection(filter: { id: { eq: $id } }) {
      edges {
        cursor
        node {
          ...CoreCourseFields
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;

export const CREATE_COURSE = gql`
  ${CORE_COURSE_FIELDS}
  mutation CreateCourse(
    $additional_information: String
    $assessment: String
    $author: UUID
    $brand_id: Int!
    $course_code: String
    $course_code2: String
    $course_features: String
    $course_guide_url: String
    $course_name: String
    $course_price: BigFloat
    $course_type: String
    $curriculum_introduction: String
    $delivery: [String]
    $delivery_long_text: String
    $duration_and_study_load: String
    $duration_length: String
    $duration_unit: String
    $entry_requirements: String
    $fee_help: [String]
    $further_study_and_education_pathways: String
    $geo_targeting: String!
    $guid: UUID
    $is_dual_qualification: Boolean
    $is_migrated_from_salesforce: Boolean
    $job_outcomes: String
    $last_published_id: Int
    $lead_cost: Float
    $level: String
    $locations: [String]
    $materials: String
    $offer_type: String
    $overview: String
    $parent_id: Int
    $pause_course_on_all_sites: Boolean!
    $payment_options: String
    $professional_recognition: String
    $published_shortcourses: Boolean
    $published_tafecourses: Boolean
    $published_training: Boolean
    $qc_note: String
    $qc_status: String
    $qualification: String
    $recognition_of_prior_learning: String
    $should_trigger_notification: Boolean
    $status: String
    $testimonies: JSON
    $video: String
    $what_youll_learn: String
    $who_is_it_for: String
    $wordpress_sync_status: String
    $work_placement: String
    $start_date: String
    $frequency: String
    $experience_level: String
    $partner_id: Int
    $internal_note: String
    $top_panel: JSON
    $course_module: JSON
    $api_id_value: String
    $grouping_value: String
    $course_code_value: String
  ) {
    insertIntocourseCollection(
      objects: [
        {
          additional_information: $additional_information
          assessment: $assessment
          author: $author
          brand_id: $brand_id
          course_code: $course_code
          course_code2: $course_code2
          course_features: $course_features
          course_guide_url: $course_guide_url
          course_name: $course_name
          course_price: $course_price
          course_type: $course_type
          curriculum_introduction: $curriculum_introduction
          delivery: $delivery
          delivery_long_text: $delivery_long_text
          duration_and_study_load: $duration_and_study_load
          duration_length: $duration_length
          duration_unit: $duration_unit
          entry_requirements: $entry_requirements
          fee_help: $fee_help
          further_study_and_education_pathways: $further_study_and_education_pathways
          geo_targeting: $geo_targeting
          guid: $guid
          is_dual_qualification: $is_dual_qualification
          is_migrated_from_salesforce: $is_migrated_from_salesforce
          job_outcomes: $job_outcomes
          last_published_id: $last_published_id
          lead_cost: $lead_cost
          level: $level
          locations: $locations
          materials: $materials
          offer_type: $offer_type
          overview: $overview
          parent_id: $parent_id
          pause_course_on_all_sites: $pause_course_on_all_sites
          payment_options: $payment_options
          professional_recognition: $professional_recognition
          published_shortcourses: $published_shortcourses
          published_tafecourses: $published_tafecourses
          published_training: $published_training
          qc_note: $qc_note
          qc_status: $qc_status
          qualification: $qualification
          recognition_of_prior_learning: $recognition_of_prior_learning
          should_trigger_notification: $should_trigger_notification
          status: $status
          testimonies: $testimonies
          video: $video
          what_youll_learn: $what_youll_learn
          who_is_it_for: $who_is_it_for
          wordpress_sync_status: $wordpress_sync_status
          work_placement: $work_placement
          start_date: $start_date
          frequency: $frequency
          experience_level: $experience_level
          partner_id: $partner_id
          internal_note: $internal_note
          top_panel: $top_panel
          course_module: $course_module
          api_id_value: $api_id_value
          grouping_value: $grouping_value
          course_code_value: $course_code_value
        }
      ]
    ) {
      affectedCount
      records {
        ...CoreCourseFields
      }
    }
  }
`;

export const UPDATE_COURSE_BY_ID = gql`
  ${CORE_COURSE_FIELDS}
  mutation UpdateCourseById(
    $nodeId: ID
    $id: Int
    $additional_information: String
    $assessment: String
    $author: UUID
    $brand_id: Int
    $course_code: String
    $course_code2: String
    $course_features: String
    $course_guide_url: String
    $course_name: String
    $course_price: BigFloat
    $course_type: String
    $curriculum_introduction: String
    $delivery: [String]
    $delivery_long_text: String
    $duration_and_study_load: String
    $duration_length: String
    $duration_unit: String
    $entry_requirements: String
    $fee_help: [String]
    $further_study_and_education_pathways: String
    $geo_targeting: String
    $guid: UUID
    $is_dual_qualification: Boolean
    $is_migrated_from_salesforce: Boolean
    $job_outcomes: String
    $last_published_id: Int
    $lead_cost: Float
    $level: String
    $locations: [String]
    $materials: String
    $offer_type: String
    $overview: String
    $parent_id: Int
    $pause_course_on_all_sites: Boolean
    $payment_options: String
    $professional_recognition: String
    $published_shortcourses: Boolean
    $published_tafecourses: Boolean
    $published_training: Boolean
    $qc_note: String
    $qc_status: String
    $qualification: String
    $recognition_of_prior_learning: String
    $should_trigger_notification: Boolean
    $status: String
    $testimonies: JSON
    $video: String
    $what_youll_learn: String
    $who_is_it_for: String
    $wordpress_sync_status: String
    $work_placement: String
    $start_date: String
    $frequency: String
    $experience_level: String
    $partner_id: Int
    $internal_note: String
    $top_panel: JSON
    $course_module: JSON
    $api_id_value: String
    $grouping_value: String
    $course_code_value: String
  ) {
    updatecourseCollection(
      set: {
        additional_information: $additional_information
        assessment: $assessment
        author: $author
        brand_id: $brand_id
        course_code: $course_code
        course_code2: $course_code2
        course_features: $course_features
        course_guide_url: $course_guide_url
        course_name: $course_name
        course_price: $course_price
        course_type: $course_type
        curriculum_introduction: $curriculum_introduction
        delivery: $delivery
        delivery_long_text: $delivery_long_text
        duration_and_study_load: $duration_and_study_load
        duration_length: $duration_length
        duration_unit: $duration_unit
        entry_requirements: $entry_requirements
        fee_help: $fee_help
        further_study_and_education_pathways: $further_study_and_education_pathways
        geo_targeting: $geo_targeting
        guid: $guid
        is_dual_qualification: $is_dual_qualification
        is_migrated_from_salesforce: $is_migrated_from_salesforce
        job_outcomes: $job_outcomes
        last_published_id: $last_published_id
        lead_cost: $lead_cost
        level: $level
        locations: $locations
        materials: $materials
        offer_type: $offer_type
        overview: $overview
        parent_id: $parent_id
        pause_course_on_all_sites: $pause_course_on_all_sites
        payment_options: $payment_options
        professional_recognition: $professional_recognition
        published_shortcourses: $published_shortcourses
        published_tafecourses: $published_tafecourses
        published_training: $published_training
        qc_note: $qc_note
        qc_status: $qc_status
        qualification: $qualification
        recognition_of_prior_learning: $recognition_of_prior_learning
        should_trigger_notification: $should_trigger_notification
        status: $status
        testimonies: $testimonies
        video: $video
        what_youll_learn: $what_youll_learn
        who_is_it_for: $who_is_it_for
        wordpress_sync_status: $wordpress_sync_status
        work_placement: $work_placement
        start_date: $start_date
        frequency: $frequency
        experience_level: $experience_level
        partner_id: $partner_id
        internal_note: $internal_note
        top_panel: $top_panel
        course_module: $course_module
        api_id_value: $api_id_value
        grouping_value: $grouping_value
        course_code_value: $course_code_value
      }
      filter: { or: { id: { eq: $id }, nodeId: { eq: $nodeId } } }
    ) {
      affectedCount
      records {
        ...CoreCourseFields
      }
    }
  }
`;

export const DELETE_COURSE_BY_ID = gql`
  mutation DeleteCourseById($nodeId: ID, $id: Int) {
    deleteFromcourseCollection(filter: { or: { id: { eq: $id }, nodeId: { eq: $nodeId } } }) {
      affectedCount
      records {
        id
      }
    }
  }
`;

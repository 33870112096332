import apolloClient from '@lib/apollo';
import { GET_PARTNERS, FILTERED_PARTNERS } from '@api/partner';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import type { Partner } from './types';

// ** Partner initial state
const partnersInitialState = {
  edges: [
    {
      cursor: '',
      node: {
        nodeId: null,
        id: 0,
        slug: '',
        name: '',
        logo: '',
      },
    },
  ],
  pageInfo: {
    endCursor: '',
    hasNextPage: false,
    hasPreviousPage: false,
    startCursor: '',
  },
};

interface PartnersState {
  edges: {
    cursor: string;
    node: Partial<Partner>;
  }[];
  pageInfo: {
    endCursor: string;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    startCursor: string;
  };
}

// ** Fetch Partners
export const fetchPartners = createAsyncThunk<Partner, any, {}>(
  'partners/fetchPartners',
  async (partnerData, { rejectWithValue }) => {
    try {
      const { data } = await apolloClient.query({
        query: GET_PARTNERS,
        variables: { ...partnerData },
        fetchPolicy: 'no-cache',
      });

      return data;
    } catch (err) {
      const error: any = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

// ** Fetch Filtered Partners
export const fetchFilteredPartners = createAsyncThunk<Partner, any, {}>(
  'partners/fetchFilteredPartners',
  async (partnerData, { rejectWithValue }) => {
    try {
      const { data } = await apolloClient.query({
        query: FILTERED_PARTNERS,
        variables: { ...partnerData },
        fetchPolicy: 'no-cache',
      });

      return data;
    } catch (err) {
      const error: any = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const partnersSlice = createSlice({
  name: 'partners',
  initialState: {
    partners: <PartnersState>{
      ...partnersInitialState,
    },
    loading: '',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPartners.fulfilled, (state, { payload }) => {
        const { partnerCollection }: any = payload;

        state.partners = partnerCollection;
      })
      .addCase(fetchFilteredPartners.fulfilled, (state, { payload }) => {
        const { partnerCollection }: any = payload;

        state.partners = partnerCollection;
      });
  },
});

export default partnersSlice.reducer;

import type { UpdatePasswordSchemaType } from '@sections/auth/supabase';

import { useForm } from 'react-hook-form';
import { Iconify } from '@components/iconify';
import { zodResolver } from '@hookform/resolvers/zod';
import { ICONS } from '@layouts/config-nav-dashboard';
import { UpdatePasswordSchema } from '@sections/auth/supabase';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import LoadingButton from '@mui/lab/LoadingButton';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import InputAdornment from '@mui/material/InputAdornment';

import { useBoolean } from 'src/hooks/use-boolean';

import { toast } from 'src/components/snackbar';
import { Form, Field } from 'src/components/hook-form';

import { updatePassword } from 'src/auth/context/supabase';

// ----------------------------------------------------------------------

interface Props {
  location?: string;
}

// ----------------------------------------------------------------------

export const ChangePasswordDialog = ({ location }: Props) => {
  // ** Hooks
  const dialog = useBoolean();
  const password = useBoolean();
  const passwordConfirm = useBoolean();

  const defaultValues = { password: '', passwordConfirm: '' };

  const methods = useForm<UpdatePasswordSchemaType>({
    mode: 'onSubmit',
    resolver: zodResolver(UpdatePasswordSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      const user = await updatePassword({ password: data.password });
      const userId = user?.data?.user?.id;

      if (userId) {
        toast.success('Password updated successfully!');

        dialog.onFalse();
      } else {
        toast.error('Error updating password. Try again!');
      }
    } catch (error) {
      toast.error('Error updating password. Try again!');
      console.error(error);
    }
  });

  return (
    <>
      {location === 'drawer' ? (
        <MenuItem
          onClick={dialog.onTrue}
          sx={{
            py: 1,
            color: 'text.secondary',
            '& svg': { width: 24, height: 24 },
            '&:hover': { color: 'text.primary' },
          }}
        >
          {ICONS.profile}

          <Box component="span" sx={{ ml: 2 }}>
            Change Password
          </Box>
        </MenuItem>
      ) : (
        <Box>
          <Button variant="contained" color="primary" onClick={dialog.onTrue}>
            Change Password
          </Button>
        </Box>
      )}

      <Dialog fullWidth maxWidth="sm" open={dialog.value} onClose={dialog.onFalse}>
        <DialogTitle>Change Password</DialogTitle>

        <Form methods={methods} onSubmit={onSubmit}>
          <DialogContent>
            <Typography sx={{ mb: 3 }}>Fill in the new password below.</Typography>

            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{ xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' }}
            >
              <Field.Text
                name="password"
                label="Password"
                placeholder="6+ characters"
                type={password.value ? 'text' : 'password'}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={password.onToggle} edge="end">
                        <Iconify
                          icon={password.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'}
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <Field.Text
                name="passwordConfirm"
                label="Confirm password"
                type={passwordConfirm.value ? 'text' : 'password'}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={passwordConfirm.onToggle} edge="end">
                        <Iconify
                          icon={passwordConfirm.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'}
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </DialogContent>

          <DialogActions>
            <Button onClick={dialog.onFalse} variant="outlined" color="inherit">
              Cancel
            </Button>
            <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
              Update Password
            </LoadingButton>
          </DialogActions>
        </Form>
      </Dialog>
    </>
  );
};

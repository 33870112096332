import apolloClient from '@lib/apollo';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  CREATE_ROLE,
  GET_ROLE_BY_ID,
  DELETE_ROLE_BY_ID,
  UPDATE_ROLE_BY_ID,
  GET_ROLE_BY_NODE_ID,
} from '@api/role';

import type { Role } from '../types';

// ** Role initial state
const roleInitialState = {
  nodeId: null,
  id: 0,
  name: '',
  description: null,
};

// ** Fetch Single Role
export const fetchSingleRole = createAsyncThunk<Role, { nodeId: string }, {}>(
  'role/fetchSingleRole',
  async (nodeId, { rejectWithValue }) => {
    try {
      const { data } = await apolloClient.query({
        query: GET_ROLE_BY_NODE_ID,
        variables: { ...nodeId },
        fetchPolicy: 'no-cache',
      });

      return data;
    } catch (err) {
      const error: any = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

// ** Fetch Single Role By ID
export const fetchSingleRoleById = createAsyncThunk<Role, { id: number }, {}>(
  'role/fetchSingleRoleById',
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await apolloClient.query({
        query: GET_ROLE_BY_ID,
        variables: { ...id },
        fetchPolicy: 'no-cache',
      });

      return data;
    } catch (err) {
      const error: any = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

// ** Create Role
export const createRole = createAsyncThunk<Role, Partial<Role>, {}>(
  'role/createRole',
  async (roleData, { rejectWithValue }) => {
    try {
      const { data } = await apolloClient.mutate({
        mutation: CREATE_ROLE,
        variables: { ...roleData },
        fetchPolicy: 'no-cache',
      });

      return data;
    } catch (err) {
      const error: any = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

// ** Update Role
export const updateRole = createAsyncThunk<Role, Partial<Role>, {}>(
  'role/updateRole',
  async (roleData, { rejectWithValue }) => {
    try {
      const { data } = await apolloClient.mutate({
        mutation: UPDATE_ROLE_BY_ID,
        variables: { ...roleData },
        fetchPolicy: 'no-cache',
      });

      return data;
    } catch (err) {
      const error: any = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

// ** Delete Role
export const deleteRole = createAsyncThunk<Role, Partial<Role>, {}>(
  'role/deleteRole',
  async (roleData, { rejectWithValue }) => {
    try {
      const { data } = await apolloClient.mutate({
        mutation: DELETE_ROLE_BY_ID,
        variables: { ...roleData },
        fetchPolicy: 'no-cache',
      });

      return data;
    } catch (err) {
      const error: any = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const singleRoleSlice = createSlice({
  name: 'role',
  initialState: {
    role: <Partial<Role>>{
      ...roleInitialState,
    },
    loading: '',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSingleRole.fulfilled, (state, { payload }) => {
        const { node }: any = payload;

        state.role = node;
      })
      .addCase(fetchSingleRoleById.fulfilled, (state, { payload }) => {
        const {
          rolesCollection: { edges },
        }: any = payload;

        state.role = edges[0].node;
      })
      .addCase(createRole.fulfilled, (state, { payload }) => {
        const {
          insertIntorolesCollection: { records },
        }: any = payload;

        state.role = records[0];
      })
      .addCase(updateRole.fulfilled, (state, { payload }) => {
        const {
          updaterolesCollection: { records },
        }: any = payload;

        state.role = records[0];
      })
      .addCase(deleteRole.fulfilled, (state, { payload }) => {
        const {
          deleteFromrolesCollection: { records },
        }: any = payload;

        state.role = records[0];
      });
  },
});

export default singleRoleSlice.reducer;

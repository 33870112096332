import { z as zod } from 'zod';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';

import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';
import { RouterLink } from 'src/routes/components';

import { Logo } from 'src/components/logo';
import { Iconify } from 'src/components/iconify';
import { Form, Field } from 'src/components/hook-form';

import { resetPassword } from 'src/auth/context/supabase';

import { removeSession } from './helpers';
import { AuthenticationFooter } from './components/footer';

// ----------------------------------------------------------------------

export type ResetPasswordSchemaType = zod.infer<typeof ResetPasswordSchema>;

export const ResetPasswordSchema = zod.object({
  email: zod
    .string()
    .min(1, { message: 'Email is required!' })
    .email({ message: 'Email must be a valid email address!' }),
});

// ----------------------------------------------------------------------

export const SupabaseResetPasswordView = () => {
  const router = useRouter();

  useEffect(() => {
    (async () => {
      // Clear existing session
      await removeSession();
    })();
  }, []);

  const defaultValues = {
    email: '',
  };

  const methods = useForm<ResetPasswordSchemaType>({
    resolver: zodResolver(ResetPasswordSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      await resetPassword({ email: data.email });

      // Clear existing session
      await removeSession();

      // Re-route
      router.push(paths.auth.supabase.verify);
    } catch (error) {
      console.error(error);
    }
  });

  const renderHead = (
    <Stack
      spacing={2}
      sx={{ mb: 5, alignItems: 'center', textAlign: 'center', whiteSpace: 'pre-line' }}
    >
      <Logo disableLink width={145} height={52} />

      <Typography variant="h5">Reset your password?</Typography>

      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
        {`Please enter the email address associated with your account and we'll send you a link to reset your password.`}
      </Typography>
    </Stack>
  );

  const renderForm = (
    <Stack spacing={3} marginBlockEnd={5}>
      <Field.Text
        autoFocus
        name="email"
        label="Email address"
        placeholder="example@gmail.com"
        InputLabelProps={{ shrink: true }}
      />

      <LoadingButton
        fullWidth
        size="large"
        type="submit"
        variant="contained"
        loading={isSubmitting}
        loadingIndicator="Sending request..."
      >
        Send request
      </LoadingButton>

      <Link
        component={RouterLink}
        href={paths.auth.supabase.signIn}
        color="inherit"
        variant="subtitle2"
        sx={{ gap: 0.5, alignSelf: 'center', alignItems: 'center', display: 'inline-flex' }}
      >
        <Iconify width={16} icon="eva:arrow-ios-back-fill" />
        Return to sign in
      </Link>
    </Stack>
  );

  return (
    <>
      {renderHead}

      <Form methods={methods} onSubmit={onSubmit}>
        {renderForm}
      </Form>

      <AuthenticationFooter />
    </>
  );
};

import { signOut } from 'src/auth/context/supabase/action';

export const removeSession = async () => {
  // Clear existing session
  window.localStorage.removeItem('bId');
  window.localStorage.removeItem('bIds');
  window.localStorage.removeItem('bS');

  await signOut();
};

import { Icon } from '@iconify/react';

import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { Tooltip, IconButton } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';

interface FilterProps {
  value: string;
  handleSetValue: (val: string) => void;
  handleFilter: () => void;
  handleFetchNotifications: (
    event: React.SyntheticEvent,
    newValue: string,
    hasSearchTerm: boolean
  ) => void;
}

export const Filter = (props: FilterProps) => {
  // ** Props
  const { value, handleSetValue, handleFilter, handleFetchNotifications } = props;

  return (
    <Stack direction="row" padding={2} spacing={2} justifyContent="flex-end">
      <TextField
        size="small"
        value={value}
        placeholder="Search notifications..."
        onChange={(e) => handleSetValue(e.target.value)}
        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
          if (e.key === 'Enter') handleFilter();
        }}
        sx={{ minWidth: '70%', '& .MuiInputBase-root': { pr: 0 }, '& input': { py: 1, pl: 2 } }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="start">
              <Tooltip placement="top" title="Search">
                <IconButton onClick={handleFilter}>
                  <Icon fontSize={18} icon="hugeicons:search-01" />
                </IconButton>
              </Tooltip>

              {value && (
                <Tooltip placement="top" title="Clear">
                  <IconButton onClick={(event) => handleFetchNotifications(event, 'all', true)}>
                    <Icon fontSize={20} icon="material-symbols:close-rounded" />
                  </IconButton>
                </Tooltip>
              )}
            </InputAdornment>
          ),
        }}
      />
    </Stack>
  );
};

import type { AppDispatch } from '@redux/index';

import { z as zod } from 'zod';
import { toast } from 'sonner';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { zodResolver } from '@hookform/resolvers/zod';
import { updateAccount } from '@redux/features/account/single';
import { fetchSingleUserById } from '@redux/features/user/single';

import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import LoadingButton from '@mui/lab/LoadingButton';
import InputAdornment from '@mui/material/InputAdornment';

import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';

import { useBoolean } from 'src/hooks/use-boolean';

import { Logo } from 'src/components/logo';
import { Iconify } from 'src/components/iconify';
import { Form, Field } from 'src/components/hook-form';

import { updatePassword } from 'src/auth/context/supabase';

import { passwordSchema } from './config';
import { removeSession } from './helpers';
import { AuthenticationFooter } from './components/footer';

// ----------------------------------------------------------------------

export type UpdatePasswordSchemaType = zod.infer<typeof UpdatePasswordSchema>;

export const UpdatePasswordSchema = zod
  .object({
    password: passwordSchema,
    passwordConfirm: passwordSchema,
  })
  .refine((data) => data.password === data.passwordConfirm, {
    message: 'Passwords do not match!',
    path: ['passwordConfirm'],
  });

// ----------------------------------------------------------------------

export const SupabaseUpdatePasswordView = () => {
  // ** States
  const [errorMsg, setErrorMsg] = useState('');

  // ** Hooks
  const router = useRouter();
  const dispatch = useDispatch<AppDispatch>();
  const password = useBoolean();
  const passwordConfirm = useBoolean();

  const defaultValues = { password: '', passwordConfirm: '' };

  const methods = useForm<UpdatePasswordSchemaType>({
    resolver: zodResolver(UpdatePasswordSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = handleSubmit(async (data) => {
    try {
      const user = await updatePassword({ password: data.password });
      const userId = user?.data?.user?.id;

      if (userId) {
        const userProfile = await dispatch(fetchSingleUserById({ id: userId }));

        const {
          profilesCollection: { edges },
        }: any = userProfile.payload;

        const { account_id }: any = edges[0].node;

        if (account_id) {
          // Update account
          const updatedAccount = await dispatch(
            updateAccount({
              id: account_id,
              status: 'publish',
              should_trigger_notification: false,
              managed_through_self_service: true,
            })
          );

          const {
            updateaccountCollection: { records },
          }: any = updatedAccount.payload;

          console.log('Records: ', records);

          toast.success('Password updated successfully!');

          // Clear existing session
          await removeSession();

          // Re-route
          router.push(paths.auth.supabase.signIn);
          router.refresh();
        }
      } else {
        toast.error('Error updating password. Try again!');
      }
    } catch (error) {
      console.error(error);
      setErrorMsg(error instanceof Error ? error.message : error);
    }
  });

  const renderHead = (
    <Stack
      spacing={2}
      sx={{ mb: 5, alignItems: 'center', textAlign: 'center', whiteSpace: 'pre-line' }}
    >
      <Logo disableLink width={145} height={52} />

      <Typography variant="h5">Update Password</Typography>

      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
        Please enter the new password below.
      </Typography>
    </Stack>
  );

  const renderForm = (
    <Stack spacing={3} marginBlockEnd={5}>
      <Field.Text
        name="password"
        label="Password"
        placeholder="6+ characters"
        type={password.value ? 'text' : 'password'}
        InputLabelProps={{ shrink: true }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={password.onToggle} edge="end">
                <Iconify icon={password.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'} />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <Field.Text
        name="passwordConfirm"
        label="Confirm password"
        type={passwordConfirm.value ? 'text' : 'password'}
        InputLabelProps={{ shrink: true }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={passwordConfirm.onToggle} edge="end">
                <Iconify
                  icon={passwordConfirm.value ? 'solar:eye-bold' : 'solar:eye-closed-bold'}
                />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <LoadingButton
        fullWidth
        type="submit"
        size="large"
        variant="contained"
        loading={isSubmitting}
        loadingIndicator="Updating password..."
      >
        Update password
      </LoadingButton>
    </Stack>
  );

  return (
    <>
      {renderHead}

      {!!errorMsg && (
        <Alert severity="error" sx={{ mb: 3 }}>
          {errorMsg}
        </Alert>
      )}

      <Form methods={methods} onSubmit={onSubmit}>
        {renderForm}
      </Form>

      <AuthenticationFooter />
    </>
  );
};

import { gql } from '@apollo/client';

const CORE_COURSE_SUBJECT_FIELDS = gql`
  fragment CoreCourseSubjectFields on course_subject {
    nodeId
    id
    course_id
    subject_id
  }
`;

export const GET_COURSE_SUBJECTS = gql`
  ${CORE_COURSE_SUBJECT_FIELDS}
  query GetCourseSubject($first: Int, $last: Int, $before: Cursor, $after: Cursor) {
    course_subjectCollection(first: $first, last: $last, before: $before, after: $after) {
      edges {
        cursor
        node {
          ...CoreCourseSubjectFields
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
`;

export const FILTERED_COURSE_SUBJECTS = gql`
  ${CORE_COURSE_SUBJECT_FIELDS}
  query FilteredCourseSubject(
    $first: Int
    $last: Int
    $before: Cursor
    $after: Cursor
    $course_id: Int
    $subject_id: Int
  ) {
    course_subjectCollection(
      first: $first
      last: $last
      before: $before
      after: $after
      filter: { or: { course_id: { eq: $course_id }, subject_id: { eq: $subject_id } } }
    ) {
      edges {
        cursor
        node {
          ...CoreCourseSubjectFields
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
`;

export const GET_COURSE_SUBJECT_BY_NODE_ID = gql`
  ${CORE_COURSE_SUBJECT_FIELDS}
  query GetCourseSubjectByNodeId($nodeId: ID!) {
    node(nodeId: $nodeId) {
      nodeId
      ... on course_subject {
        ...CoreCourseSubjectFields
      }
    }
  }
`;

export const GET_COURSE_SUBJECT_BY_ID = gql`
  ${CORE_COURSE_SUBJECT_FIELDS}
  query GetCourseSubjectById($id: Int!) {
    course_subjectCollection(filter: { id: { eq: $id } }) {
      edges {
        cursor
        node {
          ...CoreCourseSubjectFields
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;

export const CREATE_COURSE_SUBJECT = gql`
  ${CORE_COURSE_SUBJECT_FIELDS}
  mutation CreateCourseSubject($records: any!) {
    insertIntocourse_subjectCollection(objects: $records) {
      affectedCount
      records {
        ...CoreCourseSubjectFields
      }
    }
  }
`;

export const UPDATE_COURSE_SUBJECT_BY_ID = gql`
  ${CORE_COURSE_SUBJECT_FIELDS}
  mutation UpdateCourseSubjectById($nodeId: ID, $id: Int, $course_id: Int, $subject_id: Int) {
    updatecourse_subjectCollection(
      set: { course_id: $course_id, subject_id: $subject_id }
      filter: { or: { id: { eq: $id }, nodeId: { eq: $nodeId } } }
    ) {
      affectedCount
      records {
        ...CoreCourseSubjectFields
      }
    }
  }
`;

export const DELETE_COURSE_SUBJECT_BY_ID = gql`
  mutation DeleteCourseSubjectById($ids: [Int!]!) {
    deleteFromcourse_subjectCollection(filter: { id: { in: $ids } }, atMost: 50) {
      affectedCount
      records {
        id
      }
    }
  }
`;

import apolloClient from '@lib/apollo';
import { GET_ACCOUNTS, FILTERED_ACCOUNTS } from '@api/account';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import type { Account } from './types';

// ** Account initial state
const accountsInitialState = {
  edges: [
    {
      cursor: '',
      node: {
        nodeId: null,
        id: 0,
        salesforce_account_id: null,
        account_name: '',
        abn: null,
        billing_country: null,
        billing_street: null,
        billing_city: null,
        billing_state: null,
        billing_postal_code: null,
        first_name: null,
        last_name: null,
        contact_email: null,
        invoice_email: null,
        phone: null,
        provider_type: null,
        rto_code: null,
        managed_through_self_service: false,
        new_business_flag: false,
        parent_id: null,
        status: null,
        author: null,
        salesforce_sync_status_account: null,
        salesforce_sync_status_brands: null,
        is_migrated_from_salesforce: false,
        should_trigger_notification: false,
        qc_note: null,
        last_published_id: null,
        qc_status: null,
        system_note: null,
        is_fully_synced: null,
      },
    },
  ],
  pageInfo: {
    endCursor: '',
    hasNextPage: false,
    hasPreviousPage: false,
    startCursor: '',
  },
};

interface AccountsState {
  edges: {
    cursor: string;
    node: Partial<Account>;
  }[];
  pageInfo: {
    endCursor: string;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    startCursor: string;
  };
}

// ** Fetch Accounts
export const fetchAccounts = createAsyncThunk<Account, Partial<Account>, {}>(
  'accounts/fetchAccounts',
  async (accountData, { rejectWithValue }) => {
    try {
      const { data } = await apolloClient.query({
        query: GET_ACCOUNTS,
        variables: { ...accountData },
        fetchPolicy: 'no-cache',
      });

      return data;
    } catch (err) {
      const error: any = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

// ** Fetch Filtered Accounts
export const fetchFilteredAccounts = createAsyncThunk<Account, any, {}>(
  'accounts/fetchFilteredAccounts',
  async (accountData, { rejectWithValue }) => {
    try {
      const { data } = await apolloClient.query({
        query: FILTERED_ACCOUNTS,
        variables: { ...accountData },
        fetchPolicy: 'no-cache',
      });

      return data;
    } catch (err) {
      const error: any = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const accountsSlice = createSlice({
  name: 'accounts',
  initialState: {
    accounts: <AccountsState>{
      ...accountsInitialState,
    },
    loading: '',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAccounts.fulfilled, (state, { payload }) => {
        const { accountCollection }: any = payload;

        state.accounts = accountCollection;
      })
      .addCase(fetchFilteredAccounts.fulfilled, (state, { payload }) => {
        const { accountCollection }: any = payload;

        state.accounts = accountCollection;
      });
  },
});

export default accountsSlice.reducer;

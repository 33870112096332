import { gql } from '@apollo/client';

const CORE_NOTIFICATION_FIELDS = gql`
  fragment CoreNotificationFields on notification {
    nodeId
    id
    title
    content
    notification_type
    visibility
    account_id
    brand_id
    course_id
    created_at
    author
    read_at
    read_by
    required_permission_id
    account {
      account_name
    }
    brand {
      provider_name
      logo
    }
    course {
      course_name
    }
  }
`;

export const GET_NOTIFICATIONS = gql`
  ${CORE_NOTIFICATION_FIELDS}
  query GetNotifications($first: Int, $last: Int, $before: Cursor, $after: Cursor) {
    notificationCollection(
      first: $first
      last: $last
      before: $before
      after: $after
      orderBy: [{ created_at: DescNullsLast }, { read_at: DescNullsFirst }]
    ) {
      edges {
        cursor
        node {
          ...CoreNotificationFields
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;

export const NULL_FILTERED_NOTIFICATIONS = gql`
  ${CORE_NOTIFICATION_FIELDS}
  query NullFilteredNotifications(
    $first: Int
    $last: Int
    $before: Cursor
    $after: Cursor
    $account_id: Int
    $brand_id: Int
    $course_id: Int
    $title: String
    $notification_type: String
    $visibility: String
    $required_permission_id: BigInt
  ) {
    notificationCollection(
      first: $first
      last: $last
      before: $before
      after: $after
      filter: {
        or: {
          account_id: { eq: $account_id }
          brand_id: { eq: $brand_id }
          course_id: { eq: $course_id }
          title: { ilike: $title }
          notification_type: { eq: $notification_type }
          visibility: { eq: $visibility }
          read_by: { is: NULL }
          required_permission_id: { eq: $required_permission_id }
        }
      }
      orderBy: [{ created_at: DescNullsLast }, { read_at: DescNullsFirst }]
    ) {
      edges {
        cursor
        node {
          ...CoreNotificationFields
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;

export const FILTERED_NOTIFICATIONS = gql`
  ${CORE_NOTIFICATION_FIELDS}
  query FilteredNotifications(
    $first: Int
    $last: Int
    $before: Cursor
    $after: Cursor
    $account_id: Int
    $brand_id: Int
    $course_id: Int
    $title: String
    $notification_type: String
    $visibility: String
    $read_by: UUID
    $required_permission_id: BigInt
  ) {
    notificationCollection(
      first: $first
      last: $last
      before: $before
      after: $after
      filter: {
        or: {
          account_id: { eq: $account_id }
          brand_id: { eq: $brand_id }
          course_id: { eq: $course_id }
          title: { ilike: $title }
          notification_type: { eq: $notification_type }
          visibility: { eq: $visibility }
          read_by: { eq: $read_by }
          required_permission_id: { eq: $required_permission_id }
        }
      }
      orderBy: [{ created_at: DescNullsLast }, { read_at: DescNullsFirst }]
    ) {
      edges {
        cursor
        node {
          ...CoreNotificationFields
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;

export const GET_NOTIFICATION_BY_NODE_ID = gql`
  ${CORE_NOTIFICATION_FIELDS}
  query GetNotificationByNodeId($nodeId: ID!) {
    node(nodeId: $nodeId) {
      nodeId
      ... on notification {
        ...CoreNotificationFields
      }
    }
  }
`;

export const GET_NOTIFICATION_BY_ID = gql`
  ${CORE_NOTIFICATION_FIELDS}
  query GetNotificationById($id: Int!) {
    notificationCollection(filter: { id: { eq: $id } }) {
      edges {
        cursor
        node {
          ...CoreNotificationFields
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;

export const UPDATE_NOTIFICATION_BY_ID = gql`
  ${CORE_NOTIFICATION_FIELDS}
  mutation UpdateNotificationById($nodeId: ID, $id: Int, $read_by: UUID) {
    updatenotificationCollection(
      set: { read_by: $read_by }
      filter: { or: { id: { eq: $id }, nodeId: { eq: $nodeId } } }
    ) {
      affectedCount
      records {
        ...CoreNotificationFields
      }
    }
  }
`;

import { gql } from '@apollo/client';

const CORE_ROLE_FIELDS = gql`
  fragment CoreRoleFields on roles {
    nodeId
    id
    name
    description
  }
`;

export const GET_ROLES = gql`
  ${CORE_ROLE_FIELDS}
  query GetRoles($first: Int, $last: Int, $before: Cursor, $after: Cursor) {
    rolesCollection(first: $first, last: $last, before: $before, after: $after) {
      edges {
        cursor
        node {
          ...CoreRoleFields
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;

export const FILTERED_ROLES = gql`
  ${CORE_ROLE_FIELDS}
  query FilteredRoles(
    $first: Int
    $last: Int
    $before: Cursor
    $after: Cursor
    $name: String
    $description: String
  ) {
    rolesCollection(
      first: $first
      last: $last
      before: $before
      after: $after
      filter: { or: { name: { eq: $name }, description: { eq: $description } } }
    ) {
      edges {
        cursor
        node {
          ...CoreRoleFields
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;

export const GET_ROLE_BY_NODE_ID = gql`
  ${CORE_ROLE_FIELDS}
  query GetRoleByNodeId($nodeId: ID!) {
    node(nodeId: $nodeId) {
      nodeId
      ... on roles {
        ...CoreRoleFields
      }
    }
  }
`;

export const GET_ROLE_BY_ID = gql`
  ${CORE_ROLE_FIELDS}
  query GetRoleById($id: Int!) {
    rolesCollection(filter: { id: { eq: $id } }) {
      edges {
        cursor
        node {
          ...CoreRoleFields
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;

export const CREATE_ROLE = gql`
  ${CORE_ROLE_FIELDS}
  mutation CreateRole($name: String!, $description: String) {
    insertIntorolesCollection(objects: [{ name: $name, description: $description }]) {
      affectedCount
      records {
        ...CoreRoleFields
      }
    }
  }
`;

export const UPDATE_ROLE_BY_ID = gql`
  ${CORE_ROLE_FIELDS}
  mutation UpdateRoleById($nodeId: ID, $id: Int, $name: String, $description: String) {
    updaterolesCollection(
      set: { name: $name, description: $description }
      filter: { or: { id: { eq: $id }, nodeId: { eq: $nodeId } } }
    ) {
      affectedCount
      records {
        ...CoreRoleFields
      }
    }
  }
`;

export const DELETE_ROLE_BY_ID = gql`
  mutation DeleteRoleById($id: Int!) {
    deleteFromrolesCollection(filter: { id: { eq: $id } }) {
      affectedCount
      records {
        id
      }
    }
  }
`;

import { paths } from 'src/routes/paths';

import { Iconify } from 'src/components/iconify';

import { ICONS } from './config-nav-dashboard';

// ----------------------------------------------------------------------

export const _account = [
  {
    label: 'Home',
    href: '/',
    icon: <Iconify icon="solar:home-angle-bold-duotone" />,
  },
  {
    label: 'Profile',
    href: paths.dashboard.user.profile,
    icon: ICONS.profile,
  },
  {
    label: 'Notifications',
    href: paths.dashboard.chat,
    icon: ICONS.notification,
  },
];

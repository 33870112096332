import { gql } from '@apollo/client';

const CORE_COURSE_CAMPUS_FIELDS = gql`
  fragment CoreCourseCampusFields on course_campus_location {
    nodeId
    id
    course_id
    location_data_id
  }
`;

export const GET_COURSE_CAMPUSES = gql`
  ${CORE_COURSE_CAMPUS_FIELDS}
  query GetCourseCampus($first: Int, $last: Int, $before: Cursor, $after: Cursor) {
    course_campus_locationCollection(first: $first, last: $last, before: $before, after: $after) {
      edges {
        cursor
        node {
          ...CoreCourseCampusFields
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
`;

export const FILTERED_COURSE_CAMPUSES = gql`
  ${CORE_COURSE_CAMPUS_FIELDS}
  query FilteredCourseCampus(
    $first: Int
    $last: Int
    $before: Cursor
    $after: Cursor
    $course_id: Int
    $location_data_id: Int
  ) {
    course_campus_locationCollection(
      first: $first
      last: $last
      before: $before
      after: $after
      filter: { or: { course_id: { eq: $course_id }, location_data_id: { eq: $location_data_id } } }
    ) {
      edges {
        cursor
        node {
          ...CoreCourseCampusFields
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
`;

export const GET_COURSE_CAMPUS_BY_NODE_ID = gql`
  ${CORE_COURSE_CAMPUS_FIELDS}
  query GetCourseCampusByNodeId($nodeId: ID!) {
    node(nodeId: $nodeId) {
      nodeId
      ... on course_campus_location {
        ...CoreCourseCampusFields
      }
    }
  }
`;

export const GET_COURSE_CAMPUS_BY_ID = gql`
  ${CORE_COURSE_CAMPUS_FIELDS}
  query GetCourseCampusById($id: Int!) {
    course_campus_locationCollection(filter: { id: { eq: $id } }) {
      edges {
        cursor
        node {
          ...CoreCourseCampusFields
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;

export const CREATE_COURSE_CAMPUS = gql`
  ${CORE_COURSE_CAMPUS_FIELDS}
  mutation CreateCourseCampus($records: any!) {
    insertIntocourse_campus_locationCollection(objects: $records) {
      affectedCount
      records {
        ...CoreCourseCampusFields
      }
    }
  }
`;

export const UPDATE_COURSE_CAMPUS_BY_ID = gql`
  ${CORE_COURSE_CAMPUS_FIELDS}
  mutation UpdateCourseCampusById($nodeId: ID, $id: Int, $course_id: Int, $location_data_id: Int) {
    updatecourse_campus_locationCollection(
      set: { course_id: $course_id, location_data_id: $location_data_id }
      filter: { or: { id: { eq: $id }, nodeId: { eq: $nodeId } } }
    ) {
      affectedCount
      records {
        ...CoreCourseCampusFields
      }
    }
  }
`;

export const DELETE_COURSE_CAMPUS_BY_ID = gql`
  mutation DeleteCourseCampusById($ids: [Int!]!) {
    deleteFromcourse_campus_locationCollection(filter: { id: { in: $ids } }, atMost: 50) {
      affectedCount
      records {
        id
      }
    }
  }
`;

import { gql } from '@apollo/client';

const CORE_USER_ROLE_FIELDS = gql`
  fragment CoreUserRoleFields on user_roles {
    nodeId
    id
    user_id
    role_id
  }
`;

export const GET_USER_ROLES = gql`
  ${CORE_USER_ROLE_FIELDS}
  query GetUserRoles($first: Int, $last: Int, $before: Cursor, $after: Cursor) {
    user_rolesCollection(first: $first, last: $last, before: $before, after: $after) {
      edges {
        cursor
        node {
          ...CoreUserRoleFields
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;

export const FILTERED_USER_ROLES = gql`
  ${CORE_USER_ROLE_FIELDS}
  query FilteredUserRoles(
    $first: Int
    $last: Int
    $before: Cursor
    $after: Cursor
    $user_id: UUID
    $roleIds: [BigInt]
  ) {
    user_rolesCollection(
      first: $first
      last: $last
      before: $before
      after: $after
      filter: { or: { user_id: { eq: $user_id }, role_id: { in: $roleIds } } }
    ) {
      edges {
        cursor
        node {
          ...CoreUserRoleFields
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;

export const GET_USER_ROLE_BY_NODE_ID = gql`
  ${CORE_USER_ROLE_FIELDS}
  query GetUserRoleByNodeId($nodeId: ID!) {
    node(nodeId: $nodeId) {
      nodeId
      ... on user_roles {
        ...CoreUserRoleFields
      }
    }
  }
`;

export const GET_USER_ROLE_BY_ID = gql`
  ${CORE_USER_ROLE_FIELDS}
  query GetUserRoleById($id: Int!) {
    user_rolesCollection(filter: { id: { eq: $id } }) {
      edges {
        cursor
        node {
          ...CoreUserRoleFields
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;

export const CREATE_USER_ROLE = gql`
  ${CORE_USER_ROLE_FIELDS}
  mutation CreateUserRole($user_id: UUID!, $role_id: BigInt!) {
    insertIntouser_rolesCollection(objects: [{ user_id: $user_id, role_id: $role_id }]) {
      affectedCount
      records {
        ...CoreUserRoleFields
      }
    }
  }
`;

export const UPDATE_USER_ROLE_BY_ID = gql`
  ${CORE_USER_ROLE_FIELDS}
  mutation UpdateUserRoleById($nodeId: ID, $id: Int, $user_id: UUID, $role_id: BigInt) {
    updateuser_rolesCollection(
      set: { user_id: $user_id, role_id: $role_id }
      filter: { or: { id: { eq: $id }, nodeId: { eq: $nodeId } } }
    ) {
      affectedCount
      records {
        ...CoreUserRoleFields
      }
    }
  }
`;

export const DELETE_USER_ROLE_BY_ID = gql`
  mutation DeleteUserRoleById($id: Int!) {
    deleteFromuser_rolesCollection(filter: { id: { eq: $id } }) {
      affectedCount
      records {
        id
      }
    }
  }
`;

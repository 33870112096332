import apolloClient from '@lib/apollo';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  CREATE_QUALIFICATION,
  DELETE_QUALIFICATION_BY_ID,
  UPDATE_QUALIFICATION_BY_ID,
  GET_QUALIFICATION_BY_NODE_ID,
} from '@api/qualification';

import type { Qualification } from '../types';

// ** Qualification initial state
const qualificationInitialState = {
  nodeId: null,
  course_code: '',
  course_name: '',
};

// ** Fetch Single Qualification
export const fetchSingleQualification = createAsyncThunk<Qualification, { nodeId: string }, {}>(
  'qualification/fetchSingleQualification',
  async (nodeId, { rejectWithValue }) => {
    try {
      const { data } = await apolloClient.query({
        query: GET_QUALIFICATION_BY_NODE_ID,
        variables: { ...nodeId },
        fetchPolicy: 'no-cache',
      });

      return data;
    } catch (err) {
      const error: any = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

// ** Create Qualification
export const createQualification = createAsyncThunk<Qualification, Partial<Qualification>, {}>(
  'qualification/createQualification',
  async (qualificationData, { rejectWithValue }) => {
    try {
      const { data } = await apolloClient.mutate({
        mutation: CREATE_QUALIFICATION,
        variables: { ...qualificationData },
        fetchPolicy: 'no-cache',
      });

      return data;
    } catch (err) {
      const error: any = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

// ** Update Qualification
export const updateQualification = createAsyncThunk<Qualification, Partial<Qualification>, {}>(
  'qualification/updateQualification',
  async (qualificationData, { rejectWithValue }) => {
    try {
      const { data } = await apolloClient.mutate({
        mutation: UPDATE_QUALIFICATION_BY_ID,
        variables: { ...qualificationData },
        fetchPolicy: 'no-cache',
      });

      return data;
    } catch (err) {
      const error: any = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

// ** Delete Qualification
export const deleteQualification = createAsyncThunk<Qualification, Partial<Qualification>, {}>(
  'qualification/deleteQualification',
  async (qualificationData, { rejectWithValue }) => {
    try {
      const { data } = await apolloClient.mutate({
        mutation: DELETE_QUALIFICATION_BY_ID,
        variables: { ...qualificationData },
        fetchPolicy: 'no-cache',
      });

      return data;
    } catch (err) {
      const error: any = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const singleQualificationSlice = createSlice({
  name: 'qualification',
  initialState: {
    qualification: <Partial<Qualification>>{
      ...qualificationInitialState,
    },
    loading: '',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSingleQualification.fulfilled, (state, { payload }) => {
        const { node }: any = payload;

        state.qualification = node;
      })
      .addCase(createQualification.fulfilled, (state, { payload }) => {
        const {
          insertIntoqualificationCollection: { records },
        }: any = payload;

        state.qualification = records[0];
      })
      .addCase(updateQualification.fulfilled, (state, { payload }) => {
        const {
          updatequalificationCollection: { records },
        }: any = payload;

        state.qualification = records[0];
      })
      .addCase(deleteQualification.fulfilled, (state, { payload }) => {
        const {
          deleteFromqualificationCollection: { records },
        }: any = payload;

        state.qualification = records[0];
      });
  },
});

export default singleQualificationSlice.reducer;

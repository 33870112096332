import apolloClient from '@lib/apollo';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { GET_RECOGNITIONS, FILTERED_RECOGNITIONS } from '@api/recognition';

import type { Recognition } from './types';

// ** Recognition initial state
const recognitionsInitialState = {
  edges: [
    {
      cursor: '',
      node: {
        nodeId: null,
        id: 0,
        slug: '',
        name: '',
        logo: '',
        description: '',
      },
    },
  ],
  pageInfo: {
    endCursor: '',
    hasNextPage: false,
    hasPreviousPage: false,
    startCursor: '',
  },
};

interface RecognitionsState {
  edges: {
    cursor: string;
    node: Partial<Recognition>;
  }[];
  pageInfo: {
    endCursor: string;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    startCursor: string;
  };
}

// ** Fetch Recognitions
export const fetchRecognitions = createAsyncThunk<Recognition, any, {}>(
  'recognitions/fetchRecognitions',
  async (recognitionData, { rejectWithValue }) => {
    try {
      const { data } = await apolloClient.query({
        query: GET_RECOGNITIONS,
        variables: { ...recognitionData },
        fetchPolicy: 'no-cache',
      });

      return data;
    } catch (err) {
      const error: any = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

// ** Fetch Filtered Recognitions
export const fetchFilteredRecognitions = createAsyncThunk<Recognition, any, {}>(
  'recognitions/fetchFilteredRecognitions',
  async (recognitionData, { rejectWithValue }) => {
    try {
      const { data } = await apolloClient.query({
        query: FILTERED_RECOGNITIONS,
        variables: { ...recognitionData },
        fetchPolicy: 'no-cache',
      });

      return data;
    } catch (err) {
      const error: any = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const recognitionsSlice = createSlice({
  name: 'recognitions',
  initialState: {
    recognitions: <RecognitionsState>{
      ...recognitionsInitialState,
    },
    loading: '',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchRecognitions.fulfilled, (state, { payload }) => {
        const { recognitionCollection }: any = payload;

        state.recognitions = recognitionCollection;
      })
      .addCase(fetchFilteredRecognitions.fulfilled, (state, { payload }) => {
        const { recognitionCollection }: any = payload;

        state.recognitions = recognitionCollection;
      });
  },
});

export default recognitionsSlice.reducer;

import { gql } from '@apollo/client';

const CORE_PARTNER_FIELDS = gql`
  fragment CorePartnerFields on partner {
    nodeId
    id
    slug
    name
    logo
  }
`;

export const GET_PARTNERS = gql`
  ${CORE_PARTNER_FIELDS}
  query GetPartners($first: Int, $last: Int, $before: Cursor, $after: Cursor) {
    partnerCollection(first: $first, last: $last, before: $before, after: $after) {
      edges {
        cursor
        node {
          ...CorePartnerFields
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
`;

export const FILTERED_PARTNERS = gql`
  ${CORE_PARTNER_FIELDS}
  query FilteredPartners(
    $first: Int
    $last: Int
    $before: Cursor
    $after: Cursor
    $slug: String
    $name: String
  ) {
    partnerCollection(
      first: $first
      last: $last
      before: $before
      after: $after
      filter: { or: { slug: { ilike: $slug }, name: { ilike: $name } } }
    ) {
      edges {
        cursor
        node {
          ...CorePartnerFields
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
`;

export const GET_PARTNER_BY_NODE_ID = gql`
  ${CORE_PARTNER_FIELDS}
  query GetPartnerByNodeId($nodeId: ID!) {
    node(nodeId: $nodeId) {
      nodeId
      ... on partner {
        ...CorePartnerFields
      }
    }
  }
`;

export const GET_PARTNER_BY_ID = gql`
  ${CORE_PARTNER_FIELDS}
  query GetPartnerById($id: Int!) {
    partnerCollection(filter: { id: { eq: $id } }) {
      edges {
        cursor
        node {
          ...CorePartnerFields
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;

export const CREATE_PARTNER = gql`
  ${CORE_PARTNER_FIELDS}
  mutation CreatePartner($slug: String!, $name: String!, $logo: String) {
    insertIntopartnerCollection(objects: [{ slug: $slug, name: $name, logo: $logo }]) {
      affectedCount
      records {
        ...CorePartnerFields
      }
    }
  }
`;

export const UPDATE_PARTNER_BY_ID = gql`
  ${CORE_PARTNER_FIELDS}
  mutation UpdatePartnerById($nodeId: ID, $id: Int, $slug: String, $name: String, $logo: String) {
    updatepartnerCollection(
      set: { slug: $slug, name: $name, logo: $logo }
      filter: { or: { id: { eq: $id }, nodeId: { eq: $nodeId } } }
    ) {
      affectedCount
      records {
        ...CorePartnerFields
      }
    }
  }
`;

export const DELETE_PARTNER_BY_ID = gql`
  mutation DeletePartnerById($nodeId: ID, $id: Int) {
    deleteFrompartnerCollection(filter: { or: { id: { eq: $id }, nodeId: { eq: $nodeId } } }) {
      affectedCount
      records {
        id
      }
    }
  }
`;

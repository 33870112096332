import apolloClient from '@lib/apollo';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { GET_CAMPUS_LOCATIONS, FILTERED_CAMPUS_LOCATIONS } from '@api/campus';

import type { Campus } from './types';

// ** Campus initial state
const campusesInitialState = {
  edges: [
    {
      cursor: '',
      node: {
        nodeId: null,
        id: 0,
        location_point: null,
        location_address: null,
        location_campus: null,
        location_displayed_address: null,
        data_type: null,
        radius: null,
        polygon: null,
        account_id: 0,
        metadata: null,
      },
    },
  ],
  pageInfo: {
    endCursor: '',
    hasNextPage: false,
    hasPreviousPage: false,
    startCursor: '',
  },
};

interface CampusesState {
  edges: {
    cursor: string;
    node: Partial<Campus>;
  }[];
  pageInfo: {
    endCursor: string;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    startCursor: string;
  };
}

// ** Fetch Campuses
export const fetchCampuses = createAsyncThunk<Campus, Partial<Campus>, {}>(
  'campuses/fetchCampuses',
  async (campusData, { rejectWithValue }) => {
    try {
      const { data } = await apolloClient.query({
        query: GET_CAMPUS_LOCATIONS,
        variables: { ...campusData },
        fetchPolicy: 'no-cache',
      });

      return data;
    } catch (err) {
      const error: any = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

// ** Fetch Filtered Campuses
export const fetchFilteredCampuses = createAsyncThunk<Campus, any, {}>(
  'campuses/fetchFilteredCampuses',
  async (campusData, { rejectWithValue }) => {
    try {
      const { data } = await apolloClient.query({
        query: FILTERED_CAMPUS_LOCATIONS,
        variables: { ...campusData },
        fetchPolicy: 'no-cache',
      });

      return data;
    } catch (err) {
      const error: any = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const campusesSlice = createSlice({
  name: 'campuses',
  initialState: {
    campuses: <CampusesState>{
      ...campusesInitialState,
    },
    loading: '',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCampuses.fulfilled, (state, { payload }) => {
        const { campus_locationCollection }: any = payload;

        state.campuses = campus_locationCollection;
      })
      .addCase(fetchFilteredCampuses.fulfilled, (state, { payload }) => {
        const { campus_locationCollection }: any = payload;

        state.campuses = campus_locationCollection;
      });
  },
});

export default campusesSlice.reducer;

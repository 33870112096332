import apolloClient from '@lib/apollo';
import { GET_ROLES, FILTERED_ROLES } from '@api/role';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import type { Role } from './types';

// ** Role initial state
const rolesInitialState = {
  edges: [
    {
      cursor: '',
      node: {
        nodeId: null,
        id: 0,
        name: '',
        description: null,
      },
    },
  ],
  pageInfo: {
    endCursor: '',
    hasNextPage: false,
    hasPreviousPage: false,
    startCursor: '',
  },
};

interface RolesState {
  edges: {
    cursor: string;
    node: Partial<Role>;
  }[];
  pageInfo: {
    endCursor: string;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    startCursor: string;
  };
}

// ** Fetch Roles
export const fetchRoles = createAsyncThunk<Role, any, {}>(
  'roles/fetchRoles',
  async (roleData, { rejectWithValue }) => {
    try {
      const { data } = await apolloClient.query({
        query: GET_ROLES,
        variables: { ...roleData },
        fetchPolicy: 'no-cache',
      });

      return data;
    } catch (err) {
      const error: any = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

// ** Fetch Filtered Roles
export const fetchFilteredRoles = createAsyncThunk<Role, any, {}>(
  'roles/fetchFilteredRoles',
  async (roleData, { rejectWithValue }) => {
    try {
      const { data } = await apolloClient.query({
        query: FILTERED_ROLES,
        variables: { ...roleData },
        fetchPolicy: 'no-cache',
      });

      return data;
    } catch (err) {
      const error: any = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const rolesSlice = createSlice({
  name: 'roles',
  initialState: {
    roles: <RolesState>{
      ...rolesInitialState,
    },
    loading: '',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchRoles.fulfilled, (state, { payload }) => {
        const { rolesCollection }: any = payload;

        state.roles = rolesCollection;
      })
      .addCase(fetchFilteredRoles.fulfilled, (state, { payload }) => {
        const { rolesCollection }: any = payload;

        state.roles = rolesCollection;
      });
  },
});

export default rolesSlice.reducer;

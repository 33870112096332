import apolloClient from '@lib/apollo';
import { GET_COURSES, FILTERED_COURSES } from '@api/course';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import type { Course } from './types';

// ** Course initial state
const coursesInitialState = {
  edges: [
    {
      cursor: '',
      node: {
        nodeId: null,
        id: 0,
        additional_information: null,
        assessment: null,
        author: null,
        brand_id: 0,
        course_code: null,
        course_code2: null,
        course_features: null,
        course_guide_url: null,
        course_name: null,
        course_price: null,
        course_type: null,
        curriculum_introduction: null,
        delivery: null,
        delivery_long_text: null,
        duration_and_study_load: null,
        duration_length: null,
        duration_unit: null,
        entry_requirements: null,
        fee_help: null,
        further_study_and_education_pathways: null,
        geo_targeting: 'NONE',
        guid: null,
        is_dual_qualification: null,
        is_migrated_from_salesforce: null,
        job_outcomes: null,
        last_published_id: null,
        level: null,
        locations: null,
        materials: null,
        offer_type: null,
        overview: null,
        parent_id: null,
        pause_course_on_all_sites: false,
        payment_options: null,
        professional_recognition: null,
        published_shortcourses: null,
        published_tafecourses: null,
        published_training: null,
        qc_note: null,
        qc_status: null,
        qualification: null,
        recognition_of_prior_learning: null,
        should_trigger_notification: null,
        status: null,
        testimonies: null,
        updated_at: null,
        video: null,
        what_youll_learn: null,
        who_is_it_for: null,
        wordpress_sync_status: null,
        work_placement: null,
        system_note: null,
        start_date: null,
        frequency: null,
        experience_level: null,
        partner_id: 0,
        internal_note: null,
        top_panel: null,
        course_module: null,
        api_id_value: null,
        grouping_value: null,
        course_code_value: null,
        lead_cost: null,
      },
    },
  ],
  pageInfo: {
    endCursor: '',
    hasNextPage: false,
    hasPreviousPage: false,
    startCursor: '',
  },
};

interface CoursesState {
  edges: {
    cursor: string;
    node: Partial<Course>;
  }[];
  pageInfo: {
    endCursor: string;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    startCursor: string;
  };
}

// ** Fetch Courses
export const fetchCourses = createAsyncThunk<Course, Partial<Course>, {}>(
  'courses/fetchCourses',
  async (courseData, { rejectWithValue }) => {
    try {
      const { data } = await apolloClient.query({
        query: GET_COURSES,
        variables: { ...courseData },
        fetchPolicy: 'no-cache',
      });

      return data;
    } catch (err) {
      const error: any = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

// ** Fetch Filtered Courses
export const fetchFilteredCourses = createAsyncThunk<Course, any, {}>(
  'courses/fetchFilteredCourses',
  async (courseData, { rejectWithValue }) => {
    try {
      const { data } = await apolloClient.query({
        query: FILTERED_COURSES,
        variables: { ...courseData },
        fetchPolicy: 'no-cache',
      });

      return data;
    } catch (err) {
      const error: any = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const coursesSlice = createSlice({
  name: 'courses',
  initialState: {
    courses: <CoursesState>{
      ...coursesInitialState,
    },
    loading: '',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCourses.fulfilled, (state, { payload }) => {
        const { courseCollection }: any = payload;

        state.courses = courseCollection;
      })
      .addCase(fetchFilteredCourses.fulfilled, (state, { payload }) => {
        const { courseCollection }: any = payload;

        state.courses = courseCollection;
      });
  },
});

export default coursesSlice.reducer;

import users from '@redux/features/user';
import roles from '@redux/features/role';
import brands from '@redux/features/brand';
import courses from '@redux/features/course';
import authedUser from '@redux/features/auth';
import campuses from '@redux/features/campus';
import accounts from '@redux/features/account';
import partners from '@redux/features/partner';
import subjects from '@redux/features/subject';
import userRoles from '@redux/features/user-role';
import { configureStore } from '@reduxjs/toolkit';
import singleUser from '@redux/features/user/single';
import singleRole from '@redux/features/role/single';
import recognitions from '@redux/features/recognition';
import singleBrand from '@redux/features/brand/single';
import activeBrand from '@redux/features/brand/active';
import authSession from '@redux/features/auth/session';
import singleCourse from '@redux/features/course/single';
import singleCampus from '@redux/features/campus/single';
import notifications from '@redux/features/notification';
import authedUserBrands from '@redux/features/brand/list';
import qualifications from '@redux/features/qualification';
import courseCampuses from '@redux/features/course-campus';
import singleAccount from '@redux/features/account/single';
import singleSubject from '@redux/features/subject/single';
import singlePartner from '@redux/features/partner/single';
import courseSubjects from '@redux/features/course-subject';
import singleUserRole from '@redux/features/user-role/single';
import singleRecognition from '@redux/features/recognition/single';
import courseRecognitions from '@redux/features/course-recognition';
import singleNotification from '@redux/features/notification/single';
import singleCourseCampus from '@redux/features/course-campus/single';
import singleQualification from '@redux/features/qualification/single';
import singleCourseSubject from '@redux/features/course-subject/single';
import singleCourseRecognition from '@redux/features/course-recognition/single';

export const store = configureStore({
  reducer: {
    // Add reducers here
    authedUser,
    authSession,
    users,
    singleUser,
    userRoles,
    singleUserRole,
    roles,
    singleRole,
    accounts,
    singleAccount,
    brands,
    singleBrand,
    activeBrand,
    authedUserBrands,
    courses,
    singleCourse,
    campuses,
    singleCampus,
    subjects,
    singleSubject,
    recognitions,
    singleRecognition,
    partners,
    singlePartner,
    courseSubjects,
    singleCourseSubject,
    courseCampuses,
    singleCourseCampus,
    qualifications,
    singleQualification,
    notifications,
    singleNotification,
    courseRecognitions,
    singleCourseRecognition,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

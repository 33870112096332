import { gql } from '@apollo/client';

const CORE_CAMPUS_FIELDS = gql`
  fragment CoreCampusFields on campus_location {
    nodeId
    id
    location_point
    location_address
    location_campus
    location_displayed_address
    data_type
    radius
    polygon
    account_id
    metadata
  }
`;

export const GET_CAMPUS_LOCATIONS = gql`
  ${CORE_CAMPUS_FIELDS}
  query GetCampusLocations($first: Int, $last: Int, $before: Cursor, $after: Cursor) {
    campus_locationCollection(first: $first, last: $last, before: $before, after: $after) {
      edges {
        cursor
        node {
          ...CoreCampusFields
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
`;

export const FILTERED_CAMPUS_LOCATIONS = gql`
  ${CORE_CAMPUS_FIELDS}
  query FilteredCampusLocations(
    $first: Int
    $last: Int
    $before: Cursor
    $after: Cursor
    $account_id: Int
    $location_campus: String
    $location_displayed_address: String
  ) {
    campus_locationCollection(
      first: $first
      last: $last
      before: $before
      after: $after
      filter: {
        or: {
          account_id: { eq: $account_id }
          location_campus: { ilike: $location_campus }
          location_displayed_address: { ilike: $location_displayed_address }
        }
      }
    ) {
      edges {
        cursor
        node {
          ...CoreCampusFields
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
`;

export const GET_CAMPUS_LOCATION_BY_NODE_ID = gql`
  ${CORE_CAMPUS_FIELDS}
  query GetCampusLocationByNodeId($nodeId: ID!) {
    node(nodeId: $nodeId) {
      nodeId
      ... on campus_location {
        ...CoreCampusFields
      }
    }
  }
`;

export const GET_CAMPUS_LOCATION_BY_ID = gql`
  ${CORE_CAMPUS_FIELDS}
  query GetCampusLocationById($id: Int!) {
    campus_locationCollection(filter: { id: { eq: $id } }) {
      edges {
        cursor
        node {
          ...CoreCampusFields
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;

export const CREATE_CAMPUS_LOCATION = gql`
  ${CORE_CAMPUS_FIELDS}
  mutation CreateCampusLocation(
    $location_point: Opaque
    $location_address: String
    $location_campus: String
    $location_displayed_address: String
    $data_type: String
    $radius: String
    $polygon: Opaque
    $account_id: Int!
    $metadata: JSON
  ) {
    insertIntocampus_locationCollection(
      objects: [
        {
          location_point: $location_point
          location_address: $location_address
          location_campus: $location_campus
          location_displayed_address: $location_displayed_address
          data_type: $data_type
          radius: $radius
          polygon: $polygon
          account_id: $account_id
          metadata: $metadata
        }
      ]
    ) {
      affectedCount
      records {
        ...CoreCampusFields
      }
    }
  }
`;

export const UPDATE_CAMPUS_LOCATION_BY_ID = gql`
  ${CORE_CAMPUS_FIELDS}
  mutation UpdateCampusLocationById(
    $nodeId: ID
    $id: Int
    $location_point: Opaque
    $location_address: String
    $location_campus: String
    $location_displayed_address: String
    $data_type: String
    $radius: String
    $polygon: Opaque
    $account_id: Int!
    $metadata: JSON
  ) {
    updatecampus_locationCollection(
      set: {
        location_point: $location_point
        location_address: $location_address
        location_campus: $location_campus
        location_displayed_address: $location_displayed_address
        data_type: $data_type
        radius: $radius
        polygon: $polygon
        account_id: $account_id
        metadata: $metadata
      }
      filter: { or: { id: { eq: $id }, nodeId: { eq: $nodeId } } }
    ) {
      affectedCount
      records {
        ...CoreCampusFields
      }
    }
  }
`;

export const DELETE_CAMPUS_LOCATION_BY_ID = gql`
  mutation DeleteCampusLocationById($nodeId: ID, $id: Int) {
    deleteFromcampus_locationCollection(
      filter: { or: { id: { eq: $id }, nodeId: { eq: $nodeId } } }
    ) {
      affectedCount
      records {
        id
      }
    }
  }
`;

import type { RootState, AppDispatch } from '@redux/index';
import type { ButtonBaseProps } from '@mui/material/ButtonBase';

import { Icon } from '@iconify/react';
import { stringAvatar } from '@utils/avatar';
import { Scrollbar } from '@components/scrollbar';
import { truncateString } from '@utils/format-string';
import { useDispatch, useSelector } from 'react-redux';
import { fetchFilteredBrands } from '@redux/features/brand';
import { setActiveBrand } from '@redux/features/brand/active';
import { useRef, useState, useEffect, useCallback } from 'react';
import { setAuthedUserBrands } from '@redux/features/brand/list';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ButtonBase from '@mui/material/ButtonBase';
import { TextField, IconButton, Typography, InputAdornment } from '@mui/material';

import { useRouter, useParams } from 'src/routes/hooks';

import { Label } from 'src/components/label';
import { Iconify } from 'src/components/iconify';
import { usePopover, CustomPopover } from 'src/components/custom-popover';

// ----------------------------------------------------------------------

export const customStrikeThroughStyles = (spacing: number, status: string) => ({
  '&::after': {
    content: '""',
    position: 'absolute',
    left: spacing,
    right: spacing,
    top: '50%',
    height: '2px',
    backgroundColor:
      status === 'review' ? 'var(--palette-warning-light)' : 'var(--palette-error-main)',
    transform: 'translateY(-50%)',
  },
});

// ----------------------------------------------------------------------

const PAGE_SIZE = 100;

// ----------------------------------------------------------------------

export type WorkspacesPopoverProps = ButtonBaseProps & {
  isClient: boolean;
};

export const WorkspacesPopover = ({ isClient, sx, ...other }: WorkspacesPopoverProps) => {
  // ** Hooks
  const popover = usePopover();
  const dispatch = useDispatch<AppDispatch>();
  const router = useRouter();
  const { brandId = '' } = useParams();
  const observer = useRef<IntersectionObserver | null>(null);
  const { authedUser } = useSelector((state: RootState) => state.authedUser);
  const { activeBrand } = useSelector((state: RootState) => state.activeBrand);

  // ** States
  const [brandsData, setBrandsData] = useState<any[]>([]);
  const [brandsDataPageInfo, setBrandsDataPageInfo] = useState<any>();
  const [page, setPage] = useState<number>(0);
  const [loadedFirstBatch, setLoadedFirstBatch] = useState<boolean>(false);
  const [value, setValue] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [workspace, setWorkspace] = useState<any>();
  const [hasNoMatchingResults, setHasNoMatchingResults] = useState<boolean>(false);

  const getBrands = useCallback(
    async (pageNum: number) => {
      setIsLoading(true);
      setHasNoMatchingResults(false);

      try {
        const hasNextPage = brandsDataPageInfo?.pageInfo?.hasNextPage || null;
        const loadNextPage = hasNextPage && pageNum > page;

        const results = await dispatch(
          fetchFilteredBrands({
            first: PAGE_SIZE,
            not_status: 'revision',
            ...(isClient && { account_id: authedUser.account_id }),
            ...(loadNextPage && { after: brandsDataPageInfo?.pageInfo?.endCursor }),
          })
        );

        const {
          brandCollection: { edges, pageInfo },
        }: any = results.payload;

        const brandsArray = edges.map((item: any) => item.node);
        const brandIDs = [...brandsData, ...brandsArray].map((item: any) => item.id);

        setBrandsData((prev) => (pageNum === 0 ? brandsArray : [...prev, ...brandsArray]));
        setBrandsDataPageInfo({ pageInfo });

        if (pageNum === 0) setLoadedFirstBatch(true);

        // Set brandIds in localStorage
        window.localStorage.setItem('bIds', brandIDs.toString());

        // Set active brand to local workspace
        if (brandId) {
          // Set current vars
          const uniqueArr = [
            ...[...brandsData, ...brandsArray]
              .reduce((map, obj) => map.set(obj.id, obj), new Map())
              .values(),
          ];
          const currentBrand = uniqueArr?.find((item) => item.id === +brandId);
          const currentBrandIndex = uniqueArr?.findIndex((item) => item.id === +brandId);

          setWorkspace({ ...currentBrand, index: currentBrandIndex });
        }

        // Set brands to global state
        await dispatch(
          setAuthedUserBrands((prev: any) =>
            pageNum === 0 ? brandsArray : [...prev, ...brandsArray]
          )
        );
      } catch (error) {
        console.error('Error fetching brands:', error);
      } finally {
        setIsLoading(false);
      }
    },
    [
      brandsDataPageInfo?.pageInfo?.hasNextPage,
      brandsDataPageInfo?.pageInfo?.endCursor,
      page,
      dispatch,
      isClient,
      authedUser.account_id,
      brandsData,
      brandId,
    ]
  );

  // Load more options when reaching the last item
  const lastOptionRef = useCallback(
    (node: HTMLLIElement) => {
      if (isLoading) return;

      if (observer.current) observer.current.disconnect();
      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && brandsDataPageInfo?.pageInfo?.hasNextPage) {
          setPage((prevPage) => prevPage + 1);
          getBrands(page + 1);
        }
      });
      if (node) observer.current.observe(node);
    },
    [getBrands, isLoading, page, brandsDataPageInfo]
  );

  const handleSetValue = (val: string) => {
    setValue(val);
  };

  const handleFilter = useCallback(async () => {
    setIsLoading(true);

    const filteredBrands = await dispatch(
      fetchFilteredBrands({
        first: PAGE_SIZE,
        not_status: 'revision',
        ...(isClient && { account_id: authedUser.account_id }),
        provider_name: `%${value}%`,
      })
    );

    setPage(0);

    const {
      brandCollection: { edges, pageInfo },
    }: any = filteredBrands.payload;

    const brandsArray = edges?.map((item: any) => item.node);

    if (brandsArray.length > 0) {
      setBrandsData(brandsArray);
    } else {
      setHasNoMatchingResults(true);
    }
    setBrandsDataPageInfo({ pageInfo });

    setIsLoading(false);
  }, [authedUser.account_id, dispatch, isClient, value]);

  const handleReset = () => {
    setValue('');
    getBrands(0);
  };

  useEffect(() => {
    if (!loadedFirstBatch) getBrands(0);
  }, [activeBrand, brandId, brandsData, dispatch, getBrands, loadedFirstBatch]);

  const mediaQuery = 'sm';

  const updateUrlPathname = useCallback(
    (newId: number | string) => {
      const updatedBrandUrl = `/dashboard/${newId}`;

      router.replace(updatedBrandUrl);
      router.refresh();
    },
    [router]
  );

  const handleChangeBrand = useCallback(
    async (newValue: (typeof brandsData)[0]) => {
      setWorkspace(newValue);
      popover.onClose();

      // Set brandId in localStorage
      window.localStorage.setItem('bId', `${newValue.id}`);

      // Update URL pathname
      updateUrlPathname(`${newValue.id}`);

      // Update global state
      await dispatch(setActiveBrand(newValue));
    },
    [dispatch, popover, updateUrlPathname]
  );

  return (
    <>
      {brandsData.length > 0 ? (
        <>
          <ButtonBase
            disableRipple
            onClick={popover.onOpen}
            sx={{
              py: 0.5,
              width: '100%',
              justifyContent: 'space-between',
              alignItems: 'center',
              ...(!workspace.active && customStrikeThroughStyles(0, workspace.status)),
              ...sx,
            }}
            {...other}
          >
            <Stack direction="row" spacing={0.5} sx={{ alignItems: 'center' }}>
              {workspace?.logo ? (
                <Avatar
                  alt={workspace?.provider_name}
                  src={workspace?.logo}
                  sx={{
                    width: 32,
                    height: 32,
                    '& img': { objectFit: 'contain' },
                    border: '1px solid var(--palette-grey-200)',
                    p: 0.15,
                  }}
                />
              ) : (
                <>
                  {workspace?.provider_name && (
                    <Avatar
                      {...stringAvatar(`${workspace?.provider_name}`)}
                      sx={{ width: 32, height: 32, fontSize: 16 }}
                    />
                  )}
                </>
              )}

              <Tooltip placement="top" title={workspace?.provider_name}>
                <Box
                  component="span"
                  sx={{
                    typography: 'subtitle2',
                    display: { [mediaQuery]: 'inline-flex' },
                  }}
                >
                  {workspace?.provider_name && truncateString(workspace?.provider_name, 12)}
                </Box>
              </Tooltip>
            </Stack>

            <Stack direction="row" spacing={0.5} sx={{ alignItems: 'center' }}>
              <Label
                color="info"
                sx={{
                  height: 22,
                  display: { xs: 'none', [mediaQuery]: 'inline-flex' },
                }}
              >
                {`Brand ${workspace.index + 1}`}
              </Label>

              <Iconify width={16} icon="carbon:chevron-sort" sx={{ color: 'text.disabled' }} />
            </Stack>
          </ButtonBase>

          <CustomPopover
            open={popover.open}
            anchorEl={popover.anchorEl}
            onClose={popover.onClose}
            slotProps={{ arrow: { placement: 'top-left' } }}
          >
            {!isClient && (
              <Stack spacing={1.5} pt={1} px={1}>
                <Typography variant="body2">Select Brands</Typography>
                <TextField
                  size="small"
                  value={value}
                  placeholder="Search brand name..."
                  onChange={(e: any) => {
                    // @ts-ignore
                    handleSetValue(e.target.value);
                  }}
                  onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                    if (e.key === 'Enter') {
                      handleFilter();
                    }
                  }}
                  sx={{
                    width: 224,
                    mb: 2,
                    '& .MuiInputBase-root': { pr: 0 },
                    '& input': { py: 1, pl: 1 },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start">
                        <Tooltip placement="top" title="Search">
                          <IconButton
                            onClick={(e) => {
                              // @ts-ignore
                              handleFilter();
                            }}
                          >
                            <Icon fontSize={18} icon="hugeicons:search-01" />
                          </IconButton>
                        </Tooltip>

                        {value && (
                          <Tooltip placement="top" title="Clear">
                            <IconButton
                              onClick={() => {
                                // @ts-ignore
                                handleReset();
                              }}
                            >
                              <Icon fontSize={25} icon="material-symbols:close-rounded" />
                            </IconButton>
                          </Tooltip>
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              </Stack>
            )}

            <MenuList sx={{ width: 240 }}>
              {hasNoMatchingResults ? (
                <Typography variant="subtitle2" m={2}>
                  No matching providers
                </Typography>
              ) : (
                <Scrollbar sx={{ maxHeight: 360 }}>
                  {brandsData.map((option: any, index) => {
                    const lastItem = index === brandsData.length - 1;

                    return (
                      <MenuItem
                        key={index}
                        selected={option.id === workspace?.id}
                        onClick={() => handleChangeBrand({ ...option, index })}
                        sx={{
                          height: 48,
                          ...(!option.active && customStrikeThroughStyles(8, option.status)),
                        }}
                        ref={lastItem ? lastOptionRef : null}
                      >
                        {option.logo ? (
                          <Avatar
                            alt={option.provider_name || 'Brand image'}
                            src={option.logo}
                            sx={{
                              width: 24,
                              height: 24,
                              '& img': { objectFit: 'contain' },
                              border: '1px solid var(--palette-grey-200)',
                              p: 0.15,
                            }}
                          />
                        ) : (
                          <Avatar
                            {...stringAvatar(`${option.provider_name}`)}
                            sx={{ width: 24, height: 24, fontSize: 12 }}
                          />
                        )}

                        <Tooltip placement="top" title={option.provider_name}>
                          <Box component="span" sx={{ flexGrow: 1 }}>
                            {option.provider_name && truncateString(option.provider_name, 12)}
                          </Box>
                        </Tooltip>

                        <Label color="info">{`Brand ${index + 1}`}</Label>
                      </MenuItem>
                    );
                  })}
                </Scrollbar>
              )}
            </MenuList>
          </CustomPopover>
        </>
      ) : (
        <ButtonBase
          disableRipple
          sx={{
            py: 0.5,
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
            ...sx,
          }}
          {...other}
        >
          <Stack direction="row" spacing={0.5} sx={{ alignItems: 'center' }}>
            <Avatar
              {...stringAvatar(`No Brand Available`)}
              sx={{ width: 32, height: 32, fontSize: 16 }}
            />

            <Tooltip placement="top" title={workspace?.provider_name}>
              <Box
                component="span"
                sx={{
                  typography: 'subtitle2',
                  display: { [mediaQuery]: 'inline-flex' },
                }}
              >
                No Brand Available
              </Box>
            </Tooltip>
          </Stack>
        </ButtonBase>
      )}
    </>
  );
};

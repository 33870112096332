import apolloClient from '@lib/apollo';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { GET_COURSE_CAMPUSES, FILTERED_COURSE_CAMPUSES } from '@api/course-campus';

import type { CourseCampus } from './types';

// ** Course-Campus initial state
const courseCampusesInitialState = {
  edges: [
    {
      cursor: '',
      node: {
        nodeId: null,
        id: 0,
        course_id: 0,
        location_data_id: 0,
      },
    },
  ],
  pageInfo: {
    endCursor: '',
    hasNextPage: false,
    hasPreviousPage: false,
    startCursor: '',
  },
};

interface CourseCampusesState {
  edges: {
    cursor: string;
    node: Partial<CourseCampus>;
  }[];
  pageInfo: {
    endCursor: string;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    startCursor: string;
  };
}

// ** Fetch CourseCampuses
export const fetchCourseCampuses = createAsyncThunk<CourseCampus, any, {}>(
  'courseCampuses/fetchCourseCampuses',
  async (courseCampusesData, { rejectWithValue }) => {
    try {
      const { data } = await apolloClient.query({
        query: GET_COURSE_CAMPUSES,
        variables: { ...courseCampusesData },
        fetchPolicy: 'no-cache',
      });

      return data;
    } catch (err) {
      const error: any = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

// ** Fetch Filtered CourseCampuses
export const fetchFilteredCourseCampuses = createAsyncThunk<CourseCampus, any, {}>(
  'courseCampuses/fetchFilteredCourseCampuses',
  async (courseCampusesData, { rejectWithValue }) => {
    try {
      const { data } = await apolloClient.query({
        query: FILTERED_COURSE_CAMPUSES,
        variables: { ...courseCampusesData },
        fetchPolicy: 'no-cache',
      });

      return data;
    } catch (err) {
      const error: any = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const courseCampusesSlice = createSlice({
  name: 'courseCampuses',
  initialState: {
    courseCampuses: <CourseCampusesState>{
      ...courseCampusesInitialState,
    },
    loading: '',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCourseCampuses.fulfilled, (state, { payload }) => {
        const { course_campus_locationCollection }: any = payload;

        state.courseCampuses = course_campus_locationCollection;
      })
      .addCase(fetchFilteredCourseCampuses.fulfilled, (state, { payload }) => {
        const { course_campus_locationCollection }: any = payload;

        state.courseCampuses = course_campus_locationCollection;
      });
  },
});

export default courseCampusesSlice.reducer;

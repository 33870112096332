import apolloClient from '@lib/apollo';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { GET_USER_ROLES, FILTERED_USER_ROLES } from '@api/user-role';

import type { UserRole } from './types';

// ** UserRole initial state
const userRolesInitialState = {
  edges: [
    {
      cursor: '',
      node: {
        nodeId: null,
        id: 0,
        user_id: '',
        role_id: 0,
      },
    },
  ],
  pageInfo: {
    endCursor: '',
    hasNextPage: false,
    hasPreviousPage: false,
    startCursor: '',
  },
};

interface UserRolesState {
  edges: {
    cursor: string;
    node: Partial<UserRole>;
  }[];
  pageInfo: {
    endCursor: string;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    startCursor: string;
  };
}

// ** Fetch UserRoles
export const fetchUserRoles = createAsyncThunk<UserRole, any, {}>(
  'userRoles/fetchUserRoles',
  async (userRoleData, { rejectWithValue }) => {
    try {
      const { data } = await apolloClient.query({
        query: GET_USER_ROLES,
        variables: { ...userRoleData },
        fetchPolicy: 'no-cache',
      });

      return data;
    } catch (err) {
      const error: any = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

// ** Fetch Filtered UserRoles
export const fetchFilteredUserRoles = createAsyncThunk<UserRole, any, {}>(
  'userRoles/fetchFilteredUserRoles',
  async (userRoleData, { rejectWithValue }) => {
    try {
      const { data } = await apolloClient.query({
        query: FILTERED_USER_ROLES,
        variables: { ...userRoleData },
        fetchPolicy: 'no-cache',
      });

      return data;
    } catch (err) {
      const error: any = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const userRolesSlice = createSlice({
  name: 'userRoles',
  initialState: {
    userRoles: <UserRolesState>{
      ...userRolesInitialState,
    },
    loading: '',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserRoles.fulfilled, (state, { payload }) => {
        const { user_rolesCollection }: any = payload;

        state.userRoles = user_rolesCollection;
      })
      .addCase(fetchFilteredUserRoles.fulfilled, (state, { payload }) => {
        const { user_rolesCollection }: any = payload;

        state.userRoles = user_rolesCollection;
      });
  },
});

export default userRolesSlice.reducer;

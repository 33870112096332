import apolloClient from '@lib/apollo';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  CREATE_USER_ROLE,
  GET_USER_ROLE_BY_ID,
  DELETE_USER_ROLE_BY_ID,
  UPDATE_USER_ROLE_BY_ID,
  GET_USER_ROLE_BY_NODE_ID,
} from '@api/user-role';

import type { UserRole } from '../types';

// ** UserRole initial state
const userRoleInitialState = {
  nodeId: null,
  id: 0,
  user_id: '',
  role_id: 0,
};

// ** Fetch Single UserRole
export const fetchSingleUserRole = createAsyncThunk<UserRole, { nodeId: string }, {}>(
  'userRole/fetchSingleUserRole',
  async (nodeId, { rejectWithValue }) => {
    try {
      const { data } = await apolloClient.query({
        query: GET_USER_ROLE_BY_NODE_ID,
        variables: { ...nodeId },
        fetchPolicy: 'no-cache',
      });

      return data;
    } catch (err) {
      const error: any = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

// ** Fetch Single UserRole By ID
export const fetchSingleUserRoleById = createAsyncThunk<UserRole, { id: number }, {}>(
  'userRole/fetchSingleUserRoleById',
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await apolloClient.query({
        query: GET_USER_ROLE_BY_ID,
        variables: { ...id },
        fetchPolicy: 'no-cache',
      });

      return data;
    } catch (err) {
      const error: any = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

// ** Create UserRole
export const createUserRole = createAsyncThunk<UserRole, Partial<UserRole>, {}>(
  'userRole/createUserRole',
  async (userRoleData, { rejectWithValue }) => {
    try {
      const { data } = await apolloClient.mutate({
        mutation: CREATE_USER_ROLE,
        variables: { ...userRoleData },
        fetchPolicy: 'no-cache',
      });

      return data;
    } catch (err) {
      const error: any = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

// ** Update UserRole
export const updateUserRole = createAsyncThunk<UserRole, Partial<UserRole>, {}>(
  'userRole/updateUserRole',
  async (userRoleData, { rejectWithValue }) => {
    try {
      const { data } = await apolloClient.mutate({
        mutation: UPDATE_USER_ROLE_BY_ID,
        variables: { ...userRoleData },
        fetchPolicy: 'no-cache',
      });

      return data;
    } catch (err) {
      const error: any = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

// ** Delete UserRole
export const deleteUserRole = createAsyncThunk<UserRole, Partial<UserRole>, {}>(
  'userRole/deleteUserRole',
  async (userRoleData, { rejectWithValue }) => {
    try {
      const { data } = await apolloClient.mutate({
        mutation: DELETE_USER_ROLE_BY_ID,
        variables: { ...userRoleData },
        fetchPolicy: 'no-cache',
      });

      return data;
    } catch (err) {
      const error: any = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const singleUserRoleSlice = createSlice({
  name: 'userRole',
  initialState: {
    userRole: <Partial<UserRole>>{
      ...userRoleInitialState,
    },
    loading: '',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSingleUserRole.fulfilled, (state, { payload }) => {
        const { node }: any = payload;

        state.userRole = node;
      })
      .addCase(fetchSingleUserRoleById.fulfilled, (state, { payload }) => {
        const {
          user_rolesCollection: { edges },
        }: any = payload;

        state.userRole = edges[0].node;
      })
      .addCase(createUserRole.fulfilled, (state, { payload }) => {
        const {
          insertIntouser_rolesCollection: { records },
        }: any = payload;

        state.userRole = records[0];
      })
      .addCase(updateUserRole.fulfilled, (state, { payload }) => {
        const {
          updateuser_rolesCollection: { records },
        }: any = payload;

        state.userRole = records[0];
      })
      .addCase(deleteUserRole.fulfilled, (state, { payload }) => {
        const {
          deleteFromuser_rolesCollection: { records },
        }: any = payload;

        state.userRole = records[0];
      });
  },
});

export default singleUserRoleSlice.reducer;

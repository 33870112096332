import { useBoolean } from '@hooks/use-boolean';

import {
  Box,
  Stack,
  Alert,
  Dialog,
  Button,
  Typography,
  DialogTitle,
  DialogContent,
  CircularProgress,
} from '@mui/material';

import { useRouter } from 'src/routes/hooks';

// ----------------------------------------------------------------------

interface Props {
  open: boolean;
}

export const MigrationInProgress = ({ open }: Props) => {
  // ** Hooks
  const dialog = useBoolean();
  const router = useRouter();

  const handleRefresh = () => {
    router.refresh();
  };

  return (
    <Dialog maxWidth="sm" fullWidth open={open} onClose={dialog.onFalse}>
      <DialogTitle>
        <Alert
          severity="warning"
          sx={{ justifyContent: 'center', textAlign: 'center', alignItems: 'center' }}
        >
          <Typography>Migration In Progress</Typography>
        </Alert>
      </DialogTitle>

      <DialogContent sx={{ paddingBlockEnd: 3 }}>
        <Stack spacing={4} alignItems="center">
          <Typography variant="body2" textAlign="center">
            Wait 5-10 minutes then click on the refresh button below.
          </Typography>

          <CircularProgress />

          <Box>
            <Button variant="contained" color="primary" onClick={handleRefresh}>
              Refresh
            </Button>
          </Box>

          <Typography variant="caption" textAlign="center">
            In case of a problem, contact Candlefox support via:{' '}
            <a href="mailto:clientsupport@candlefox.com">clientsupport@candlefox.com</a>.
          </Typography>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

import apolloClient from '@lib/apollo';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  CREATE_CAMPUS_LOCATION,
  GET_CAMPUS_LOCATION_BY_ID,
  DELETE_CAMPUS_LOCATION_BY_ID,
  UPDATE_CAMPUS_LOCATION_BY_ID,
  GET_CAMPUS_LOCATION_BY_NODE_ID,
} from '@api/campus';

import type { Campus } from '../types';

// ** Campus initial state
const campusInitialState = {
  nodeId: null,
  id: 0,
  location_point: null,
  location_address: null,
  location_campus: null,
  location_displayed_address: null,
  data_type: null,
  radius: null,
  polygon: null,
  account_id: 0,
  metadata: null,
};

// ** Fetch Single Campus
export const fetchSingleCampus = createAsyncThunk<Campus, { nodeId: string }, {}>(
  'campus/fetchSingleCampus',
  async (nodeId, { rejectWithValue }) => {
    try {
      const { data } = await apolloClient.query({
        query: GET_CAMPUS_LOCATION_BY_NODE_ID,
        variables: { ...nodeId },
        fetchPolicy: 'no-cache',
      });

      return data;
    } catch (err) {
      const error: any = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

// ** Fetch Single Campus By ID
export const fetchSingleCampusById = createAsyncThunk<Campus, { id: number }, {}>(
  'campus/fetchSingleCampusById',
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await apolloClient.query({
        query: GET_CAMPUS_LOCATION_BY_ID,
        variables: { ...id },
        fetchPolicy: 'no-cache',
      });

      return data;
    } catch (err) {
      const error: any = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

// ** Create Campus
export const createCampus = createAsyncThunk<Campus, Partial<Campus>, {}>(
  'campus/createCampus',
  async (campusData, { rejectWithValue }) => {
    try {
      const { data } = await apolloClient.mutate({
        mutation: CREATE_CAMPUS_LOCATION,
        variables: { ...campusData },
        fetchPolicy: 'no-cache',
      });

      return data;
    } catch (err) {
      const error: any = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

// ** Update Campus
export const updateCampus = createAsyncThunk<Campus, Partial<Campus>, {}>(
  'campus/updateCampus',
  async (campusData, { rejectWithValue }) => {
    try {
      const { data } = await apolloClient.mutate({
        mutation: UPDATE_CAMPUS_LOCATION_BY_ID,
        variables: { ...campusData },
        fetchPolicy: 'no-cache',
      });

      return data;
    } catch (err) {
      const error: any = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

// ** Delete Campus
export const deleteCampus = createAsyncThunk<Campus, Partial<Campus>, {}>(
  'campus/deleteCampus',
  async (campusData, { rejectWithValue }) => {
    try {
      const { data } = await apolloClient.mutate({
        mutation: DELETE_CAMPUS_LOCATION_BY_ID,
        variables: { ...campusData },
        fetchPolicy: 'no-cache',
      });

      return data;
    } catch (err) {
      const error: any = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const singleCampusSlice = createSlice({
  name: 'campus',
  initialState: {
    campus: <Partial<Campus>>{
      ...campusInitialState,
    },
    loading: '',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSingleCampus.fulfilled, (state, { payload }) => {
        const { node }: any = payload;

        state.campus = node;
      })
      .addCase(fetchSingleCampusById.fulfilled, (state, { payload }) => {
        const {
          campus_locationCollection: { edges },
        }: any = payload;

        state.campus = edges[0].node;
      })
      .addCase(createCampus.fulfilled, (state, { payload }) => {
        const {
          insertIntocampus_locationCollection: { records },
        }: any = payload;

        state.campus = records[0];
      })
      .addCase(updateCampus.fulfilled, (state, { payload }) => {
        const {
          updatecampus_locationCollection: { records },
        }: any = payload;

        state.campus = records[0];
      })
      .addCase(deleteCampus.fulfilled, (state, { payload }) => {
        const {
          deleteFromcampus_locationCollection: { records },
        }: any = payload;

        state.campus = records[0];
      });
  },
});

export default singleCampusSlice.reducer;

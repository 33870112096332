import apolloClient from '@lib/apollo';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  CREATE_COURSE,
  GET_COURSE_BY_ID,
  DELETE_COURSE_BY_ID,
  UPDATE_COURSE_BY_ID,
  GET_COURSE_BY_NODE_ID,
} from '@api/course';

import type { Course } from '../types';

// ** Course initial state
const courseInitialState = {
  nodeId: null,
  id: 0,
  additional_information: null,
  assessment: null,
  author: null,
  brand_id: 0,
  course_code: null,
  course_code2: null,
  course_features: null,
  course_guide_url: null,
  course_name: null,
  course_price: null,
  course_type: null,
  curriculum_introduction: null,
  delivery: null,
  delivery_long_text: null,
  duration_and_study_load: null,
  duration_length: null,
  duration_unit: null,
  entry_requirements: null,
  fee_help: null,
  further_study_and_education_pathways: null,
  geo_targeting: 'NONE',
  guid: null,
  is_dual_qualification: null,
  is_migrated_from_salesforce: null,
  job_outcomes: null,
  last_published_id: null,
  level: null,
  locations: null,
  materials: null,
  offer_type: null,
  overview: null,
  parent_id: null,
  pause_course_on_all_sites: false,
  payment_options: null,
  professional_recognition: null,
  published_shortcourses: null,
  published_tafecourses: null,
  published_training: null,
  qc_note: null,
  qc_status: null,
  qualification: null,
  recognition_of_prior_learning: null,
  should_trigger_notification: null,
  status: null,
  testimonies: null,
  updated_at: null,
  video: null,
  what_youll_learn: null,
  who_is_it_for: null,
  wordpress_sync_status: null,
  work_placement: null,
  system_note: null,
  start_date: null,
  frequency: null,
  experience_level: null,
  partner_id: 0,
  internal_note: null,
  top_panel: null,
  course_module: null,
  api_id_value: null,
  grouping_value: null,
  course_code_value: null,
  lead_cost: null,
};

// ** Fetch Single Course
export const fetchSingleCourse = createAsyncThunk<Course, { nodeId: string }, {}>(
  'course/fetchSingleCourse',
  async (nodeId, { rejectWithValue }) => {
    try {
      const { data } = await apolloClient.query({
        query: GET_COURSE_BY_NODE_ID,
        variables: { ...nodeId },
        fetchPolicy: 'no-cache',
      });

      return data;
    } catch (err) {
      const error: any = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

// ** Fetch Single Course By ID
export const fetchSingleCourseById = createAsyncThunk<Course, { id: number }, {}>(
  'course/fetchSingleCourseById',
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await apolloClient.query({
        query: GET_COURSE_BY_ID,
        variables: { ...id },
        fetchPolicy: 'no-cache',
      });

      return data;
    } catch (err) {
      const error: any = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

// ** Create Course
export const createCourse = createAsyncThunk<Course, Partial<Course>, {}>(
  'course/createCourse',
  async (courseData, { rejectWithValue }) => {
    try {
      const { data } = await apolloClient.mutate({
        mutation: CREATE_COURSE,
        variables: { ...courseData },
        fetchPolicy: 'no-cache',
      });

      return data;
    } catch (err) {
      const error: any = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

// ** Update Course
export const updateCourse = createAsyncThunk<Course, Partial<Course>, {}>(
  'course/updateCourse',
  async (courseData, { rejectWithValue }) => {
    try {
      const { data } = await apolloClient.mutate({
        mutation: UPDATE_COURSE_BY_ID,
        variables: { ...courseData },
        fetchPolicy: 'no-cache',
      });

      return data;
    } catch (err) {
      const error: any = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

// ** Delete Course
export const deleteCourse = createAsyncThunk<Course, Partial<Course>, {}>(
  'course/deleteCourse',
  async (courseData, { rejectWithValue }) => {
    try {
      const { data } = await apolloClient.mutate({
        mutation: DELETE_COURSE_BY_ID,
        variables: { ...courseData },
        fetchPolicy: 'no-cache',
      });

      return data;
    } catch (err) {
      const error: any = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const singleCourseSlice = createSlice({
  name: 'course',
  initialState: {
    course: <Partial<Course>>{
      ...courseInitialState,
    },
    loading: '',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSingleCourse.fulfilled, (state, { payload }) => {
        const { node }: any = payload;

        state.course = node;
      })
      .addCase(fetchSingleCourseById.fulfilled, (state, { payload }) => {
        const {
          courseCollection: { edges },
        }: any = payload;

        state.course = edges[0].node;
      })
      .addCase(createCourse.fulfilled, (state, { payload }) => {
        const {
          insertIntocourseCollection: { records },
        }: any = payload;

        state.course = records[0];
      })
      .addCase(updateCourse.fulfilled, (state, { payload }) => {
        const {
          updatecourseCollection: { records },
        }: any = payload;

        state.course = records[0];
      })
      .addCase(deleteCourse.fulfilled, (state, { payload }) => {
        const {
          deleteFromcourseCollection: { records },
        }: any = payload;

        state.course = records[0];
      });
  },
});

export default singleCourseSlice.reducer;

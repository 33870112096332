import apolloClient from '@lib/apollo';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  CREATE_SUBJECT,
  GET_SUBJECT_BY_ID,
  DELETE_SUBJECT_BY_ID,
  UPDATE_SUBJECT_BY_ID,
  GET_SUBJECT_BY_NODE_ID,
} from '@api/subject';

import type { Subject } from '../types';

// ** Subject initial state
const subjectInitialState = {
  nodeId: null,
  id: 0,
  slug: '',
  name: '',
};

// ** Fetch Single Subject
export const fetchSingleSubject = createAsyncThunk<Subject, { nodeId: string }, {}>(
  'subject/fetchSingleSubject',
  async (nodeId, { rejectWithValue }) => {
    try {
      const { data } = await apolloClient.query({
        query: GET_SUBJECT_BY_NODE_ID,
        variables: { ...nodeId },
        fetchPolicy: 'no-cache',
      });

      return data;
    } catch (err) {
      const error: any = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

// ** Fetch Single Subject By ID
export const fetchSingleSubjectById = createAsyncThunk<Subject, { id: number }, {}>(
  'subject/fetchSingleSubjectById',
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await apolloClient.query({
        query: GET_SUBJECT_BY_ID,
        variables: { ...id },
        fetchPolicy: 'no-cache',
      });

      return data;
    } catch (err) {
      const error: any = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

// ** Create Subject
export const createSubject = createAsyncThunk<Subject, Partial<Subject>, {}>(
  'subject/createSubject',
  async (subjectData, { rejectWithValue }) => {
    try {
      const { data } = await apolloClient.mutate({
        mutation: CREATE_SUBJECT,
        variables: { ...subjectData },
        fetchPolicy: 'no-cache',
      });

      return data;
    } catch (err) {
      const error: any = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

// ** Update Subject
export const updateSubject = createAsyncThunk<Subject, Partial<Subject>, {}>(
  'subject/updateSubject',
  async (subjectData, { rejectWithValue }) => {
    try {
      const { data } = await apolloClient.mutate({
        mutation: UPDATE_SUBJECT_BY_ID,
        variables: { ...subjectData },
        fetchPolicy: 'no-cache',
      });

      return data;
    } catch (err) {
      const error: any = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

// ** Delete Subject
export const deleteSubject = createAsyncThunk<Subject, Partial<Subject>, {}>(
  'subject/deleteSubject',
  async (subjectData, { rejectWithValue }) => {
    try {
      const { data } = await apolloClient.mutate({
        mutation: DELETE_SUBJECT_BY_ID,
        variables: { ...subjectData },
        fetchPolicy: 'no-cache',
      });

      return data;
    } catch (err) {
      const error: any = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const singleSubjectSlice = createSlice({
  name: 'subject',
  initialState: {
    subject: <Partial<Subject>>{
      ...subjectInitialState,
    },
    loading: '',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSingleSubject.fulfilled, (state, { payload }) => {
        const { node }: any = payload;

        state.subject = node;
      })
      .addCase(fetchSingleSubjectById.fulfilled, (state, { payload }) => {
        const {
          subjectCollection: { edges },
        }: any = payload;

        state.subject = edges[0].node;
      })
      .addCase(createSubject.fulfilled, (state, { payload }) => {
        const {
          insertIntosubjectCollection: { records },
        }: any = payload;

        state.subject = records[0];
      })
      .addCase(updateSubject.fulfilled, (state, { payload }) => {
        const {
          updatesubjectCollection: { records },
        }: any = payload;

        state.subject = records[0];
      })
      .addCase(deleteSubject.fulfilled, (state, { payload }) => {
        const {
          deleteFromsubjectCollection: { records },
        }: any = payload;

        state.subject = records[0];
      });
  },
});

export default singleSubjectSlice.reducer;

import type { Breakpoint } from '@mui/material/styles';
import type { RootState, AppDispatch } from '@redux/index';
import type { NavSectionProps } from 'src/components/nav-section';

import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveBrand } from '@redux/features/brand/active';
import { fetchSingleBrandById } from '@redux/features/brand/single';

import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';

import { useParams } from 'src/routes/hooks';

import { varAlpha, hideScrollY } from 'src/theme/styles';

import { Logo } from 'src/components/logo';
import { Scrollbar } from 'src/components/scrollbar';
import { NavSectionMini, NavSectionVertical } from 'src/components/nav-section';

import { NavToggleButton } from '../components/nav-toggle-button';
import { WorkspacesPopover } from '../components/workspaces-popover';

// ----------------------------------------------------------------------

export type NavVerticalProps = NavSectionProps & {
  isClient: boolean;
  isNavMini: boolean;
  layoutQuery: Breakpoint;
  onToggleNav: () => void;
  slots?: {
    topArea?: React.ReactNode;
    bottomArea?: React.ReactNode;
  };
};

export const NavVertical = ({
  sx,
  data,
  slots,
  isClient,
  isNavMini,
  layoutQuery,
  onToggleNav,
  ...other
}: NavVerticalProps) => {
  // ** States
  const [brand, setBrand] = useState<any>();

  // ** Hooks
  const theme = useTheme();
  const { brandId = '' } = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const { authedUser } = useSelector((state: RootState) => state.authedUser);

  useEffect(() => {
    (async () => {
      if (brandId) {
        const response = await dispatch(fetchSingleBrandById({ id: +brandId }));

        const {
          brandCollection: { edges },
        }: any = response.payload;

        setBrand(edges[0].node);

        // Update active brand
        await dispatch(setActiveBrand(edges[0].node));
      }
    })();
  }, [brandId, dispatch]);

  const renderNavVertical = (
    <>
      {slots?.topArea ?? (
        <>
          {brand && (
            <Box
              sx={{
                mx: 2,
                mt: 2.5,
                mb: 1,
                p: 2,
                borderRadius: 1.25,
                backgroundColor: `var(--nav-item-root-active-bg, ${brand?.active ? varAlpha(theme.vars.palette.primary.mainChannel, 0.08) : brand?.status === 'review' ? varAlpha(theme.vars.palette.warning.mainChannel, 0.2) : varAlpha(theme.vars.palette.error.mainChannel, 0.2)})`,
              }}
            >
              {authedUser.id && <WorkspacesPopover data-slot="workspaces" isClient={isClient} />}
            </Box>
          )}
        </>
      )}

      <Scrollbar fillContent>
        <NavSectionVertical data={data} sx={{ px: 2, flex: '1 1 auto' }} {...other} />
      </Scrollbar>
    </>
  );

  const renderNavMini = (
    <>
      {slots?.topArea ?? (
        <Box sx={{ display: 'flex', justifyContent: 'center', py: 2.5 }}>
          <Logo showMini />
        </Box>
      )}

      <NavSectionMini
        data={data}
        sx={{ pb: 2, px: 0.5, ...hideScrollY, flex: '1 1 auto', overflowY: 'auto' }}
        {...other}
      />

      {slots?.bottomArea}
    </>
  );

  return (
    <Box
      sx={{
        top: 0,
        left: 0,
        height: 1,
        display: 'none',
        position: 'fixed',
        flexDirection: 'column',
        bgcolor: 'var(--layout-nav-bg)',
        zIndex: 'var(--layout-nav-zIndex)',
        width: isNavMini ? 'var(--layout-nav-mini-width)' : 'var(--layout-nav-vertical-width)',
        borderRight: `1px solid var(--layout-nav-border-color, ${varAlpha(theme.vars.palette.grey['500Channel'], 0.12)})`,
        transition: theme.transitions.create(['width'], {
          easing: 'var(--layout-transition-easing)',
          duration: 'var(--layout-transition-duration)',
        }),
        [theme.breakpoints.up(layoutQuery)]: {
          display: 'flex',
        },
        ...sx,
      }}
    >
      <NavToggleButton
        isNavMini={isNavMini}
        onClick={onToggleNav}
        sx={{
          display: 'none',
          [theme.breakpoints.up(layoutQuery)]: {
            display: 'inline-flex',
          },
        }}
      />
      {isNavMini ? renderNavMini : renderNavVertical}
    </Box>
  );
};

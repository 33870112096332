import { gql } from '@apollo/client';

const CORE_QUALIFICATION_FIELDS = gql`
  fragment CoreQualificationFields on qualification {
    nodeId
    course_code
    course_name
  }
`;

export const GET_QUALIFICATIONS = gql`
  ${CORE_QUALIFICATION_FIELDS}
  query GetQualifications($first: Int, $last: Int, $before: Cursor, $after: Cursor) {
    qualificationCollection(first: $first, last: $last, before: $before, after: $after) {
      edges {
        cursor
        node {
          ...CoreQualificationFields
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
`;

export const FILTERED_QUALIFICATIONS = gql`
  ${CORE_QUALIFICATION_FIELDS}
  query FilteredQualifications(
    $first: Int
    $last: Int
    $before: Cursor
    $after: Cursor
    $courseCodes: [String]
    $course_name: String
  ) {
    qualificationCollection(
      first: $first
      last: $last
      before: $before
      after: $after
      filter: { or: { course_code: { in: $courseCodes }, course_name: { ilike: $course_name } } }
    ) {
      edges {
        cursor
        node {
          ...CoreQualificationFields
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
`;

export const LOOKUP_QUALIFICATIONS = gql`
  ${CORE_QUALIFICATION_FIELDS}
  query LookupQualifications(
    $first: Int
    $last: Int
    $before: Cursor
    $after: Cursor
    $course_code: String
    $course_name: String
  ) {
    qualificationCollection(
      first: $first
      last: $last
      before: $before
      after: $after
      filter: { or: { course_code: { ilike: $course_code }, course_name: { ilike: $course_name } } }
    ) {
      edges {
        cursor
        node {
          ...CoreQualificationFields
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
`;

export const GET_QUALIFICATION_BY_NODE_ID = gql`
  ${CORE_QUALIFICATION_FIELDS}
  query GetQualificationByNodeId($nodeId: ID!) {
    node(nodeId: $nodeId) {
      nodeId
      ... on qualification {
        ...CoreQualificationFields
      }
    }
  }
`;

export const CREATE_QUALIFICATION = gql`
  ${CORE_QUALIFICATION_FIELDS}
  mutation CreateQualification($course_code: String, $course_name: String) {
    insertIntoqualificationCollection(
      objects: [{ course_code: $course_code, course_name: $course_name }]
    ) {
      affectedCount
      records {
        ...CoreQualificationFields
      }
    }
  }
`;

export const UPDATE_QUALIFICATION_BY_ID = gql`
  ${CORE_QUALIFICATION_FIELDS}
  mutation UpdateQualificationById($nodeId: ID, $course_code: String, $course_name: String) {
    updatequalificationCollection(
      set: { course_code: $course_code, course_name: $course_name }
      filter: { nodeId: { eq: $nodeId } }
    ) {
      affectedCount
      records {
        ...CoreQualificationFields
      }
    }
  }
`;

export const DELETE_QUALIFICATION_BY_ID = gql`
  mutation DeleteQualificationById($nodeId: ID) {
    deleteFromqualificationCollection(filter: { nodeId: { eq: $nodeId } }) {
      affectedCount
      records {
        course_code
      }
    }
  }
`;

import { gql } from '@apollo/client';

const CORE_COURSE_RECOGNITION_FIELDS = gql`
  fragment CoreCourseRecognitionFields on course_recognition {
    nodeId
    id
    course_id
    recognition_id
  }
`;

export const GET_COURSE_RECOGNITIONS = gql`
  ${CORE_COURSE_RECOGNITION_FIELDS}
  query GetCourseRecognition($first: Int, $last: Int, $before: Cursor, $after: Cursor) {
    course_recognitionCollection(first: $first, last: $last, before: $before, after: $after) {
      edges {
        cursor
        node {
          ...CoreCourseRecognitionFields
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;

export const FILTERED_COURSE_RECOGNITIONS = gql`
  ${CORE_COURSE_RECOGNITION_FIELDS}
  query FilteredCourseRecognition(
    $first: Int
    $last: Int
    $before: Cursor
    $after: Cursor
    $course_id: Int
    $recognition_id: Int
  ) {
    course_recognitionCollection(
      first: $first
      last: $last
      before: $before
      after: $after
      filter: { or: { course_id: { eq: $course_id }, recognition_id: { eq: $recognition_id } } }
    ) {
      edges {
        cursor
        node {
          ...CoreCourseRecognitionFields
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;

export const GET_COURSE_RECOGNITION_BY_NODE_ID = gql`
  ${CORE_COURSE_RECOGNITION_FIELDS}
  query GetCourseRecognitionByNodeId($nodeId: ID!) {
    node(nodeId: $nodeId) {
      nodeId
      ... on course_recognition {
        ...CoreCourseRecognitionFields
      }
    }
  }
`;

export const GET_COURSE_RECOGNITION_BY_ID = gql`
  ${CORE_COURSE_RECOGNITION_FIELDS}
  query GetCourseRecognitionById($id: Int!) {
    course_recognitionCollection(filter: { id: { eq: $id } }) {
      edges {
        cursor
        node {
          ...CoreCourseRecognitionFields
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;

export const CREATE_COURSE_RECOGNITION = gql`
  ${CORE_COURSE_RECOGNITION_FIELDS}
  mutation CreateCourseRecognition($records: any!) {
    insertIntocourse_recognitionCollection(objects: $records) {
      affectedCount
      records {
        ...CoreCourseRecognitionFields
      }
    }
  }
`;

export const UPDATE_COURSE_RECOGNITION_BY_ID = gql`
  ${CORE_COURSE_RECOGNITION_FIELDS}
  mutation UpdateCourseRecognitionById(
    $nodeId: ID
    $id: Int
    $course_id: Int
    $recognition_id: Int
  ) {
    updatecourse_recognitionCollection(
      set: { course_id: $course_id, recognition_id: $recognition_id }
      filter: { or: { id: { eq: $id }, nodeId: { eq: $nodeId } } }
    ) {
      affectedCount
      records {
        ...CoreCourseRecognitionFields
      }
    }
  }
`;

export const DELETE_COURSE_RECOGNITION_BY_ID = gql`
  mutation DeleteCourseRecognitionById($ids: [Int!]!) {
    deleteFromcourse_recognitionCollection(filter: { id: { in: $ids } }, atMost: 50) {
      affectedCount
      records {
        id
      }
    }
  }
`;

import { gql } from '@apollo/client';

const CORE_SUBJECT_FIELDS = gql`
  fragment CoreSubjectFields on subject {
    nodeId
    id
    slug
    name
  }
`;

export const GET_SUBJECTS = gql`
  ${CORE_SUBJECT_FIELDS}
  query GetSubjects($first: Int, $last: Int, $before: Cursor, $after: Cursor) {
    subjectCollection(first: $first, last: $last, before: $before, after: $after) {
      edges {
        cursor
        node {
          ...CoreSubjectFields
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
`;

export const FILTERED_SUBJECTS = gql`
  ${CORE_SUBJECT_FIELDS}
  query FilteredSubjects(
    $first: Int
    $last: Int
    $before: Cursor
    $after: Cursor
    $slug: String
    $name: String
    $ids: [Int]
  ) {
    subjectCollection(
      first: $first
      last: $last
      before: $before
      after: $after
      filter: { or: { slug: { ilike: $slug }, name: { ilike: $name }, id: { in: $ids } } }
    ) {
      edges {
        cursor
        node {
          ...CoreSubjectFields
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
`;

export const GET_SUBJECT_BY_NODE_ID = gql`
  ${CORE_SUBJECT_FIELDS}
  query GetSubjectByNodeId($nodeId: ID!) {
    node(nodeId: $nodeId) {
      nodeId
      ... on subject {
        ...CoreSubjectFields
      }
    }
  }
`;

export const GET_SUBJECT_BY_ID = gql`
  ${CORE_SUBJECT_FIELDS}
  query GetSubjectById($id: Int!) {
    subjectCollection(filter: { id: { eq: $id } }) {
      edges {
        cursor
        node {
          ...CoreSubjectFields
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
    }
  }
`;

export const CREATE_SUBJECT = gql`
  ${CORE_SUBJECT_FIELDS}
  mutation CreateSubject($slug: String, $name: String) {
    insertIntosubjectCollection(objects: [{ slug: $slug, name: $name }]) {
      affectedCount
      records {
        ...CoreSubjectFields
      }
    }
  }
`;

export const UPDATE_SUBJECT_BY_ID = gql`
  ${CORE_SUBJECT_FIELDS}
  mutation UpdateSubjectById($nodeId: ID, $id: Int, $slug: String, $name: String) {
    updatesubjectCollection(
      set: { slug: $slug, name: $name }
      filter: { or: { id: { eq: $id }, nodeId: { eq: $nodeId } } }
    ) {
      affectedCount
      records {
        ...CoreSubjectFields
      }
    }
  }
`;

export const DELETE_SUBJECT_BY_ID = gql`
  mutation DeleteSubjectById($nodeId: ID, $id: Int) {
    deleteFromsubjectCollection(filter: { or: { id: { eq: $id }, nodeId: { eq: $nodeId } } }) {
      affectedCount
      records {
        id
      }
    }
  }
`;

import type { IBrand } from 'src/types/brand';
import type { RootState, AppDispatch } from '@redux/index';
import type { ButtonBaseProps } from '@mui/material/ButtonBase';

import { Icon } from '@iconify/react';
import { stringAvatar } from '@utils/avatar';
import { Scrollbar } from '@components/scrollbar';
import { truncateString } from '@utils/format-string';
import { useDispatch, useSelector } from 'react-redux';
import { useState, useEffect, useCallback } from 'react';
import { setActiveBrand } from '@redux/features/brand/active';
import { setAuthedUserBrands } from '@redux/features/brand/list';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import ButtonBase from '@mui/material/ButtonBase';
import { TextField, IconButton, Typography, InputAdornment } from '@mui/material';

import { useRouter, useParams } from 'src/routes/hooks';

import { Label } from 'src/components/label';
import { Iconify } from 'src/components/iconify';
import { usePopover, CustomPopover } from 'src/components/custom-popover';

// ----------------------------------------------------------------------

export const customStrikeThroughStyles = (spacing: number, status: string) => ({
  '&::after': {
    content: '""',
    position: 'absolute',
    left: spacing,
    right: spacing,
    top: '50%',
    height: '2px',
    backgroundColor: status === 'review' ? 'orange' : 'red',
    transform: 'translateY(-50%)',
  },
});

// ----------------------------------------------------------------------

export type BrandsPopoverProps = ButtonBaseProps & {
  data?: Partial<IBrand>[];
  lastOptionRef: (node: HTMLLIElement) => void;
  inputType: 'search' | 'list';
  value?: string;
  handleSetValue?: (val: string) => void;
  handleFilter?: () => void;
  handleReset?: () => void;
  hasNoMatchingResults?: boolean;
};

export const BrandsPopover = ({
  data = [],
  lastOptionRef,
  inputType,
  value,
  handleSetValue,
  handleFilter,
  handleReset,
  hasNoMatchingResults,
  sx,
  ...other
}: BrandsPopoverProps) => {
  // ** Hooks
  const popover = usePopover();
  const dispatch = useDispatch<AppDispatch>();
  const router = useRouter();
  const { brandId = '' } = useParams();
  const { activeBrand } = useSelector((state: RootState) => state.activeBrand);

  // ** Vars
  const currentBrand = data.find((item) => item.id === +brandId);
  const currentBrandIndex = data.findIndex((item) => item.id === +brandId);

  // ** States
  const [workspace, setWorkspace] = useState<any>(
    brandId ? { ...currentBrand, index: currentBrandIndex } : { ...data[0], index: 0 }
  );

  useEffect(() => {
    (async () => {
      // Set active brand to local workspace
      if (brandId) {
        setWorkspace({ ...currentBrand, index: currentBrandIndex });

        // Set brands to global state
        await dispatch(setAuthedUserBrands(data));
        await dispatch(setActiveBrand(currentBrand));
      }
    })();
  }, [activeBrand, brandId, currentBrand, currentBrandIndex, data, dispatch]);

  const mediaQuery = 'sm';

  const updateUrlPathname = useCallback(
    (newId: number | string) => {
      const updatedBrandUrl = `/dashboard/${newId}`;

      router.replace(updatedBrandUrl);
      router.refresh();
    },
    [router]
  );

  const handleChangeBrand = useCallback(
    async (newValue: (typeof data)[0]) => {
      setWorkspace(newValue);
      popover.onClose();

      // Set brandId in localStorage
      window.localStorage.setItem('bId', `${newValue.id}`);

      // Update URL pathname
      updateUrlPathname(`${newValue.id}`);

      // Update global state
      await dispatch(setActiveBrand(newValue));
    },
    [dispatch, popover, updateUrlPathname]
  );

  const handleFilterBrands = (e: any) => {
    popover.onOpen(e);
    // @ts-ignore
    handleFilter();
  };

  return (
    <>
      {data.length ? (
        <>
          {inputType === 'search' && (
            <TextField
              size="small"
              value={value}
              placeholder="Search Brand Name..."
              onChange={(e: any) => {
                // @ts-ignore
                handleSetValue(e.target.value);
              }}
              onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                if (e.key === 'Enter') handleFilterBrands(e);
              }}
              sx={{ width: '100%', '& .MuiInputBase-root': { pr: 0 }, '& input': { py: 2, pl: 2 } }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">
                    <Tooltip placement="top" title="Search">
                      <IconButton onClick={handleFilterBrands}>
                        <Icon fontSize={25} icon="hugeicons:search-01" />
                      </IconButton>
                    </Tooltip>

                    {value && (
                      <Tooltip placement="top" title="Clear">
                        <IconButton
                          onClick={() => {
                            // @ts-ignore
                            handleReset();
                            popover.onClose();
                          }}
                        >
                          <Icon fontSize={32} icon="material-symbols:close-rounded" />
                        </IconButton>
                      </Tooltip>
                    )}
                  </InputAdornment>
                ),
              }}
            />
          )}

          {inputType === 'list' && (
            <ButtonBase
              disableRipple
              onClick={popover.onOpen}
              sx={{
                py: 0.5,
                width: '100%',
                height: '53.56px',
                justifyContent: 'space-between',
                alignItems: 'center',
                border: `solid 1px rgba(var(--palette-grey-500Channel) / 0.2)`,
                borderRadius: 1.25,
                ...sx,
              }}
              {...other}
            >
              <Stack direction="row" spacing={0.5} sx={{ alignItems: 'center', px: 2 }}>
                {workspace?.provider_name && (
                  <Avatar
                    {...stringAvatar(`Select Brand`)}
                    sx={{ width: 32, height: 32, fontSize: 16 }}
                  />
                )}

                <Box
                  component="span"
                  sx={{
                    typography: 'subtitle2',
                    display: { [mediaQuery]: 'inline-flex' },
                  }}
                >
                  Select Brand
                </Box>
              </Stack>

              <Stack direction="row" spacing={0.5} sx={{ alignItems: 'center' }}>
                <Label
                  color="info"
                  sx={{
                    height: 22,
                    display: { xs: 'none', [mediaQuery]: 'inline-flex' },
                  }}
                >
                  {`Brand ${workspace.index}`}
                </Label>

                <Iconify width={16} icon="carbon:chevron-sort" sx={{ color: 'text.disabled' }} />
              </Stack>
            </ButtonBase>
          )}

          <CustomPopover
            open={popover.open}
            anchorEl={popover.anchorEl}
            onClose={popover.onClose}
            slotProps={{ arrow: { placement: 'top-left' } }}
          >
            <MenuList sx={{ width: 400 }}>
              {hasNoMatchingResults ? (
                <Typography variant="subtitle2" m={2}>
                  No matching providers
                </Typography>
              ) : (
                <Scrollbar sx={{ maxHeight: 300 }}>
                  {data.map((option: any, index) => {
                    const lastItem = index === data.length - 1;

                    return (
                      <MenuItem
                        key={index}
                        selected={option.id === workspace?.id}
                        onClick={() => handleChangeBrand({ ...option, index })}
                        sx={{
                          height: 48,
                          ...(!option.active && customStrikeThroughStyles(8, option.status)),
                        }}
                        ref={lastItem ? lastOptionRef : null}
                      >
                        {option.logo ? (
                          <Avatar
                            alt={option.provider_name || 'Brand image'}
                            src={option.logo}
                            sx={{
                              width: 24,
                              height: 24,
                              '& img': { objectFit: 'contain' },
                              border: '1px solid var(--palette-grey-200)',
                              p: 0.15,
                            }}
                          />
                        ) : (
                          <Avatar
                            {...stringAvatar(`${option.provider_name}`)}
                            sx={{ width: 24, height: 24, fontSize: 12 }}
                          />
                        )}

                        <Tooltip placement="top" title={option.provider_name}>
                          <Box component="span" sx={{ flexGrow: 1 }}>
                            {option.provider_name && truncateString(option.provider_name, 36)}
                          </Box>
                        </Tooltip>

                        <Label color="info">{`Brand ${index + 1}`}</Label>
                      </MenuItem>
                    );
                  })}
                </Scrollbar>
              )}
            </MenuList>
          </CustomPopover>
        </>
      ) : (
        <ButtonBase
          disableRipple
          sx={{
            py: 0.5,
            width: '100%',
            height: '53.56px',
            justifyContent: 'space-between',
            alignItems: 'center',
            border: `solid 1px rgba(var(--palette-grey-500Channel) / 0.2)`,
            borderRadius: 1.25,
            ...sx,
          }}
          {...other}
        >
          <Stack direction="row" spacing={0.5} sx={{ pl: 2, alignItems: 'center' }}>
            <Avatar
              {...stringAvatar(`No Brand Available`)}
              sx={{ width: 32, height: 32, fontSize: 16 }}
            />

            <Tooltip placement="top" title={workspace?.provider_name}>
              <Box
                component="span"
                sx={{
                  typography: 'subtitle2',
                  display: { [mediaQuery]: 'inline-flex' },
                }}
              >
                No Brand Available
              </Box>
            </Tooltip>
          </Stack>
        </ButtonBase>
      )}
    </>
  );
};

import ReactDOM from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { Suspense, StrictMode } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';

import App from './app';
import { CONFIG } from './config-global';

// ----------------------------------------------------------------------

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

Sentry.init({
  dsn: 'https://e665d8343fe68a6d632332b767e72dab@o4508334932754432.ingest.de.sentry.io/4508334964605008',
  integrations: [],
});

root.render(
  <StrictMode>
    <HelmetProvider>
      <BrowserRouter basename={CONFIG.site.basePath}>
        <Suspense>
          <App />
        </Suspense>
      </BrowserRouter>
    </HelmetProvider>
  </StrictMode>
);

import apolloClient from '@lib/apollo';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  GET_NOTIFICATIONS,
  FILTERED_NOTIFICATIONS,
  NULL_FILTERED_NOTIFICATIONS,
} from '@api/notification';

import type { Notification } from './types';

// ** Notification initial state
const notificationsInitialState = {
  edges: [
    {
      cursor: '',
      node: {
        nodeId: null,
        id: 0,
        title: '',
        content: '',
        notification_type: undefined,
        visibility: undefined,
        account_id: 0,
        brand_id: null,
        course_id: null,
        created_at: null,
        author: null,
        read_at: null,
        read_by: null,
        required_permission_id: null,
        account: {
          account_name: undefined,
        },
        brand: {
          provider_name: null,
          logo: null,
        },
        course: {
          course_name: null,
        },
      },
    },
  ],
  pageInfo: {
    endCursor: '',
    hasNextPage: false,
    hasPreviousPage: false,
    startCursor: '',
  },
};

interface NotificationsState {
  edges: {
    cursor: string;
    node: Partial<Notification>;
  }[];
  pageInfo: {
    endCursor: string;
    hasNextPage: boolean;
    hasPreviousPage: boolean;
    startCursor: string;
  };
}

// ** Fetch Notifications
export const fetchNotifications = createAsyncThunk<Notification, any, {}>(
  'notifications/fetchNotifications',
  async (notificationData, { rejectWithValue }) => {
    try {
      const { data } = await apolloClient.query({
        query: GET_NOTIFICATIONS,
        variables: { ...notificationData },
        fetchPolicy: 'no-cache',
      });

      return data;
    } catch (err) {
      const error: any = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

// ** Fetch Filtered Notifications
export const fetchFilteredNotifications = createAsyncThunk<Notification, any, {}>(
  'notifications/fetchFilteredNotifications',
  async (notificationData, { rejectWithValue }) => {
    try {
      const { data } = await apolloClient.query({
        query: FILTERED_NOTIFICATIONS,
        variables: { ...notificationData },
        fetchPolicy: 'no-cache',
      });

      return data;
    } catch (err) {
      const error: any = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

// ** Fetch Null Filtered Notifications
export const fetchNullFilteredNotifications = createAsyncThunk<Notification, any, {}>(
  'notifications/fetchNullFilteredNotifications',
  async (notificationData, { rejectWithValue }) => {
    try {
      const { data } = await apolloClient.query({
        query: NULL_FILTERED_NOTIFICATIONS,
        variables: { ...notificationData },
        fetchPolicy: 'no-cache',
      });

      return data;
    } catch (err) {
      const error: any = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      // We got validation errors, let's return those so we can reference in our component and set form errors
      return rejectWithValue(error.response.data);
    }
  }
);

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState: {
    notifications: <NotificationsState>{
      ...notificationsInitialState,
    },
    loading: '',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchNotifications.fulfilled, (state, { payload }) => {
        const { notificationCollection }: any = payload;

        state.notifications = notificationCollection;
      })
      .addCase(fetchFilteredNotifications.fulfilled, (state, { payload }) => {
        const { notificationCollection }: any = payload;

        state.notifications = notificationCollection;
      })
      .addCase(fetchNullFilteredNotifications.fulfilled, (state, { payload }) => {
        const { notificationCollection }: any = payload;

        state.notifications = notificationCollection;
      });
  },
});

export default notificationsSlice.reducer;
